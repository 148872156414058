// firebaseHelpers.ts

import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  QueryConstraint,
  DocumentData,
  WithFieldValue,
  PartialWithFieldValue,
  QuerySnapshot,
  where,
  setDoc,
  limit,
  orderBy,
  Query,
  startAfter,
  Timestamp,
  writeBatch
} from 'firebase/firestore';

import { db } from '../auth/firebaseConfig';
import { Listing } from '../components/property/property-card/PropertyCardTypes';
import { SearchParams } from '../pages/browse-listings/BrowseListingsPage';
import { getLocationSuggestions, normalizeText } from '../data/locations';
import { deleteImage } from './firebaseStorageHelpers';
import { User } from '../types/userTypes';

interface ShareTracking {
  propertyId: string;
  sharedWith: string;
  sharedAt: Timestamp | Date;
}
export interface AutoApplyUser {
  userId: string;
  isEnabled: boolean;
  activatedAt?: Timestamp;
  expiresAt?: Timestamp;
  shareCount: number;
  sharedProperties: ShareTracking[];
  preferences?: AutoApplyPreferences; // Added preferences
  createdAt: Timestamp;
  lastUpdated: Timestamp;
  stats: {
    totalApplications: number;
    successfulApplications: number;
  };
}

interface AutoApplyPreferences {
  maxPrice: number;
  minPrice: number;
  locations: string[];
  propertyTypes: string[];
  minBedrooms: number;
  minBathrooms: number;
  mustHaveParking: boolean;
  petFriendly: boolean;
  availableFrom: Date | null;
  keywords: string[];
}

export interface AutoApplyStatus {
  isActive: boolean;
  isFeatureEnabledByUser: boolean;
  shareCount: number;
  sharesNeeded: number;
  expiryDate?: Date;
  recentApplications: AutoApplyApplication[];
  preferences?: AutoApplyPreferences;
  stats: {
    totalApplications: number;
    successfulApplications: number;
  };
}

export interface AutoApplyApplication {
  propertyId: string;
  appliedAt: Timestamp | Date;
  status: 'pending' | 'successful' | 'failed';
  propertyDetails?: {
    title: string;
    location: string;
    price: number;
    type: string;
  };
}

interface AutoApplyPreferences {
  maxPrice: number;
  minPrice: number;
  locations: string[];
  propertyTypes: string[];
  minBedrooms: number;
  minBathrooms: number;
  mustHaveParking: boolean;
  petFriendly: boolean;
  availableFrom: Date | null;
  keywords: string[];
}

// Generic type for Firestore documents
export type FirestoreDocument<T = DocumentData> = T & { id: string };

export const insertDocument = async <T extends DocumentData>(
  collectionName: string,
  data: WithFieldValue<T>,
  documentId?: string
): Promise<string> => {
  try {
    if (documentId) {
      // If documentId is provided, use setDoc to create/overwrite the document
      const docRef = doc(db, collectionName, documentId);
      await setDoc(docRef, data);
      return documentId;
    } else {
      // If no documentId is provided, use addDoc to let Firebase generate an ID
      const collectionRef = collection(db, collectionName);
      const docRef = await addDoc(collectionRef, data);
      console.log('Document inserted with generated ID: ', docRef.id);
      return docRef.id;
    }
  } catch (error) {
    console.error('Error inserting document: ', error);
    throw error;
  }
};

// Get a single document by ID
export const getDocumentById = async <T extends DocumentData>(
  collectionName: string,
  docId: string
): Promise<FirestoreDocument<T> | null> => {
  try {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...(docSnap.data() as T) };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document: ', error);
    throw error;
  }
};

export const getUserById = async (userId: string): Promise<User | null> => {
  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Found you');
      return { id: docSnap.id, ...docSnap.data() } as User;
    } else {
      console.log('No such user document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting user document: ', error);
    throw error;
  }
};

export const getDocumentByField = async <T extends DocumentData>(
  collectionName: string,
  fieldName: string,
  fieldValue: string | number
): Promise<T | null> => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where(fieldName, '==', fieldValue));
    const querySnapshot: QuerySnapshot<T> = (await getDocs(
      q
    )) as QuerySnapshot<T>;

    if (querySnapshot.empty) {
      console.log('No matching documents.', fieldName, fieldValue);
      return null;
    }

    if (querySnapshot.size > 1) {
      console.warn('More than one document found. Returning the first one.');
    }

    const doc = querySnapshot.docs[0];
    return { id: doc.id, ...doc.data() } as T;
  } catch (error) {
    console.error('Error getting document by field: ', error);
    throw error;
  }
};

// Get all documents from a collection
export const getAllDocuments = async <T extends DocumentData>(
  collectionName: string
): Promise<FirestoreDocument<T>[]> => {
  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as T)
    }));
  } catch (error) {
    console.error('Error getting documents: ', error);
    throw error;
  }
};

// Update a document
export const updateDocument = async <T extends DocumentData>(
  collectionName: string,
  docId: string,
  data: PartialWithFieldValue<T>
): Promise<void> => {
  try {
    const docRef = doc(db, collectionName, docId);
    await updateDoc(docRef, data as PartialWithFieldValue<DocumentData>);
    console.log('Document updated successfully');
  } catch (error) {
    console.error('Error updating document: ', error);
    throw error;
  }
};

// Delete a document
export const deleteDocument = async (
  collectionName: string,
  docId: string
): Promise<void> => {
  try {
    const docRef = doc(db, collectionName, docId);
    await deleteDoc(docRef);
    console.log('Document deleted successfully');
  } catch (error) {
    console.error('Error deleting document: ', error);
    throw error;
  }
};

// Query documents
export const queryDocuments = async <T extends DocumentData>(
  collectionName: string,
  constraints: QueryConstraint[]
): Promise<FirestoreDocument<T>[]> => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, ...constraints);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as T)
    }));
  } catch (error) {
    console.error('Error querying documents: ', error, collectionName);
    throw error;
  }
};

export const fetchListings = async (
  page: number,
  pageSize: number,
  searchParams: SearchParams,
  sortOption: string
): Promise<{ listings: Listing[]; total: number }> => {
  try {
    let q: Query<DocumentData> = collection(db, 'listings');

    // Apply filters

    // Apply location filter with improved matching
    if (searchParams.location && searchParams.location.trim() !== '') {
      const suggestions = getLocationSuggestions(
        searchParams.location.trim(),
        5
      );

      if (suggestions.length > 0) {
        // Get all possible variations of the top matches
        const possibleMatches = suggestions
          .map((suggestion) => suggestion.original.toLowerCase())
          .filter((value, index, self) => self.indexOf(value) === index);
        // If we have matches, use 'in' query
        q = query(q, where('location', 'in', possibleMatches));
      } else {
        // Fallback to starts with query
        const searchTerm = normalizeText(searchParams.location.trim());
        q = query(
          q,
          where('location', '>=', searchTerm),
          where('location', '<=', searchTerm + '\uf8ff')
        );
      }
    }

    // Only search active listings
    q = query(q, where('status', '==', 'active'));

    if (searchParams.minPrice > 0) {
      console.log(`Applying minimum price filter: ${searchParams.minPrice}`);
      q = query(q, where('price', '>=', searchParams.minPrice));
    }

    if (searchParams.maxPrice != null && searchParams.maxPrice > 0) {
      console.log(`Applying maximum price filter: ${searchParams.maxPrice}`);
      q = query(q, where('price', '<=', searchParams.maxPrice));
    }

    if (searchParams.bedrooms > 0) {
      console.log(`Applying bedrooms filter: >= ${searchParams.bedrooms}`);
      q = query(q, where('bedrooms', '>=', searchParams.bedrooms));
    }

    if (searchParams.bathrooms > 0) {
      console.log(`Applying bathrooms filter: >= ${searchParams.bathrooms}`);
      q = query(q, where('bathrooms', '>=', searchParams.bathrooms));
    }

    if (searchParams.propertyType !== 'Any') {
      console.log(
        `Applying property type filter: ${searchParams.propertyType}`
      );
      q = query(q, where('type', '==', searchParams.propertyType));
    }

    if (searchParams.berRating !== 'Any') {
      console.log(`Applying BER rating filter: ${searchParams.berRating}`);
      q = query(q, where('berRating', '==', searchParams.berRating));
    }

    if (searchParams.availableFrom instanceof Date) {
      const availableFromTimestamp = Timestamp.fromDate(
        searchParams.availableFrom
      );
      console.log(
        `Applying available from filter: ${searchParams.availableFrom.toISOString()}`
      );
      q = query(q, where('availableFrom', '>=', availableFromTimestamp));
    }

    if (searchParams.listingIntent !== 'Any') {
      console.log(
        `Applying listing intent filter: ${searchParams.listingIntent}`
      );
      q = query(q, where('listingIntent', '==', searchParams.listingIntent));
    }
    // Apply sorting
    if (sortOption === 'priceLowToHigh') {
      console.log('Sorting by price in ascending order');
      q = query(q, orderBy('price', 'asc'));
    } else if (sortOption === 'priceHighToLow') {
      console.log('Sorting by price in descending order');
      q = query(q, orderBy('price', 'desc'));
    } else {
      console.log('Applying default sort: dateAdded in descending order');
      q = query(q, orderBy('dateAdded', 'desc'));
    }

    // Get total count first
    const totalQuery = query(q);
    const totalSnapshot = await getDocs(totalQuery);
    const total = totalSnapshot.size;

    // Get the document to start after based on the page number
    const startAfterDoc = page > 1 
      ? await getStartAfterDoc(q, (page - 1) * pageSize) 
      : null;

    // Apply final pagination
    if (startAfterDoc) {
      q = query(q, startAfter(startAfterDoc), limit(pageSize));
    } else {
      q = query(q, limit(pageSize));
    }

    // Execute query
    const querySnapshot = await getDocs(q);
    const listings = querySnapshot.docs.map(
      (doc) =>
        ({
          id: Number(doc.id),
          ...doc.data()
        }) as Listing
    );

    return { listings, total };
  } catch (error: any) {
    if (
      error.code === 'failed-precondition' ||
      error.code === 'resource-exhausted'
    ) {
      console.error('Firestore index error:', error);
      console.log('To resolve this error, please create the following index:');
      console.log(error.details);
      // You could also send this information to your server or a logging service
    }
    throw error;
  }
};

// Helper function to get the document to start after for pagination
async function getStartAfterDoc(q: Query<DocumentData>, skipCount: number): Promise<DocumentData | null> {
  try {
    const snapshot = await getDocs(query(q, limit(skipCount)));
    const docs = snapshot.docs;
    return docs.length === skipCount ? docs[docs.length - 1] : null;
  } catch (error) {
    console.error('Error getting start after doc:', error);
    return null;
  }
}

export const updateUserInListings = async (
  userId: string,
  updatedUserData: Partial<User>
): Promise<void> => {
  try {
    const listingsRef = collection(db, 'listings');
    const q = query(listingsRef, where('propertyOwnerUid', '==', userId));
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      const listingRef = doc.ref;
      batch.update(listingRef, { user: updatedUserData });
    });

    await batch.commit();
    console.log('Updated user data in all associated listings');
  } catch (error) {
    console.error('Error updating user data in listings:', error);
    throw error;
  }
};

export const deactivateAccount = async (userId: string): Promise<void> => {
  try {
    await updateDocument('users', userId, {
      isDeactivated: true,
      deactivatedAt: Timestamp.now(),
      status: 'inactive'
    });

    // Update associated listings to be inactive
    const listings = await queryDocuments('listings', [
      where('propertyOwnerUid', '==', userId)
    ]);

    const batch = writeBatch(db);
    listings.forEach((listing) => {
      const listingRef = doc(db, 'listings', listing.id.toString());
      batch.update(listingRef, { status: 'inactive' });
    });

    await batch.commit();
    console.log('Account deactivated successfully');
  } catch (error) {
    console.error('Error deactivating account:', error);
    throw error;
  }
};

export const deleteAccount = async (userId: string): Promise<void> => {
  try {
    // Get user data first
    const userData = await getUserById(userId);
    if (!userData) {
      throw new Error('User not found');
    }

    // Delete profile photo if exists
    if (userData.photoUrl) {
      await deleteImage(userData.photoUrl);
    }

    // Delete all user's listings and their images
    const listings = await queryDocuments('listings', [
      where('propertyOwnerUid', '==', userId)
    ]);

    for (const listing of listings) {
      // Delete listing images
      if (listing.images && listing.images.length > 0) {
        for (const imageUrl of listing.images) {
          await deleteImage(imageUrl);
        }
      }
      // Delete the listing document
      await deleteDocument('listings', listing.id.toString());
    }

    // Delete all conversations
    const conversations = await queryDocuments('conversations', [
      where('participants', 'array-contains', userId)
    ]);

    for (const conversation of conversations) {
      await deleteDocument('conversations', conversation.id);
    }

    // Finally, delete user document
    await deleteDocument('users', userId);

    console.log('Account and all associated data deleted successfully');
  } catch (error) {
    console.error('Error deleting account:', error);
    throw error;
  }
};

// Helper function to check Auto Apply eligibility
export const checkAutoApplyEligibility = async (
  userId: string
): Promise<{
  isEligible: boolean;
  sharesNeeded: number;
  expiryDate?: Date;
}> => {
  try {
    const autoApplyUser = await getDocumentById<AutoApplyUser>(
      'autoApplyUsers',
      userId
    );

    if (!autoApplyUser) {
      return { isEligible: false, sharesNeeded: 2 };
    }

    const isActive =
      autoApplyUser.isEnabled &&
      autoApplyUser.expiresAt &&
      autoApplyUser.expiresAt.toDate() > new Date();

    if (isActive) {
      return {
        isEligible: true,
        sharesNeeded: 0,
        expiryDate: autoApplyUser.expiresAt?.toDate()
      };
    }

    // If expired, need 4 shares to reactivate
    const baseSharesNeeded = autoApplyUser.expiresAt ? 4 : 2;
    const sharesNeeded = Math.max(
      0,
      baseSharesNeeded - autoApplyUser.shareCount
    );

    return {
      isEligible: false,
      sharesNeeded,
      expiryDate: autoApplyUser.expiresAt?.toDate()
    };
  } catch (error) {
    console.error('Error checking Auto Apply eligibility:', error);
    return { isEligible: false, sharesNeeded: 2 };
  }
};

export const fetchAutoApplyData = async (
  autoApplyData: FirestoreDocument<AutoApplyUser> | null
): Promise<AutoApplyStatus> => {
  try {
    // Get user's auto apply document
    if (autoApplyData === null) {
      // Return default status for new users
      return {
        isActive: false,
        isFeatureEnabledByUser: false,
        shareCount: 0,
        sharesNeeded: 2,
        stats: {
          totalApplications: 0,
          successfulApplications: 0
        },
        recentApplications: []
      };
    }

    // Check if feature is currently active - explicitly handle undefined cases
    const now = new Date();
    const isActive: boolean = Boolean(
      autoApplyData.isEnabled &&
        autoApplyData.expiresAt &&
        autoApplyData.expiresAt.toDate() > now
    );

    // Calculate shares needed based on status
    let sharesNeeded = 0;
    if (!isActive) {
      // If expired, need 4 shares to reactivate, otherwise need 2 to activate
      const baseRequired = autoApplyData.expiresAt ? 4 : 2;
      sharesNeeded = Math.max(
        0,
        baseRequired - (autoApplyData.shareCount || 0)
      );
    }

    // Fetch recent applications
    const applicationsRef = collection(db, 'autoApplyApplications');
    const applicationsQuery = query(
      applicationsRef,
      where('userId', '==', autoApplyData.userId),
      orderBy('appliedAt', 'desc'),
      limit(10)
    );
    const applicationsSnapshot = await getDocs(applicationsQuery);
    const recentApplications = applicationsSnapshot.docs.map((doc) => ({
      ...(doc.data() as AutoApplyApplication)
    }));

    // Calculate stats with default values
    const stats = {
      totalApplications: autoApplyData.stats?.totalApplications ?? 0,
      successfulApplications: autoApplyData.stats?.successfulApplications ?? 0
    };

    // Construct return object with all required fields
    const status: AutoApplyStatus = {
      isActive,
      isFeatureEnabledByUser: autoApplyData.isEnabled,
      shareCount: autoApplyData.shareCount ?? 0,
      sharesNeeded,
      expiryDate: autoApplyData.expiresAt?.toDate(),
      recentApplications,
      preferences: autoApplyData.preferences,
      stats
    };
    return status;
  } catch (error) {
    console.error('Error fetching Auto Apply data:', error);
    throw new Error('Failed to fetch Auto Apply data');
  }
};

// Helper function to check if Auto Apply is ready for activation
export const checkAutoApplyActivation = async (
  userId: string
): Promise<{
  canActivate: boolean;
  currentShares: number;
  requiredShares: number;
  isReactivation: boolean;
}> => {
  try {
    const autoApplyRef = doc(db, 'autoApplyUsers', userId);
    const autoApplyDoc = await getDoc(autoApplyRef);

    if (!autoApplyDoc.exists()) {
      return {
        canActivate: false,
        currentShares: 0,
        requiredShares: 2,
        isReactivation: false
      };
    }

    const data = autoApplyDoc.data() as AutoApplyUser;
    const isReactivation = !!data.expiresAt;
    const requiredShares = isReactivation ? 4 : 2;
    const currentShares = data.shareCount ?? 0;

    return {
      canActivate: currentShares >= requiredShares,
      currentShares,
      requiredShares,
      isReactivation
    };
  } catch (error) {
    console.error('Error checking Auto Apply activation:', error);
    throw new Error('Failed to check Auto Apply activation status');
  }
};

// Helper function to get share status
export const getShareStatus = async (
  userId: string,
  propertyId: string
): Promise<{
  hasShared: boolean;
  shareTime?: Date;
  platform?: string;
}> => {
  try {
    const autoApplyRef = doc(db, 'autoApplyUsers', userId);
    const autoApplyDoc = await getDoc(autoApplyRef);

    if (!autoApplyDoc.exists()) {
      return { hasShared: false };
    }

    const data = autoApplyDoc.data() as AutoApplyUser;
    const share = data.sharedProperties?.find(
      (s) => s.propertyId === propertyId
    );

    if (!share) {
      return { hasShared: false };
    }

    return {
      hasShared: true,
      shareTime:
        share.sharedAt instanceof Timestamp
          ? share.sharedAt.toDate()
          : share.sharedAt, // Check type before calling toDate()
      platform: share.sharedWith
    };
  } catch (error) {
    console.error('Error getting share status:', error);
    throw new Error('Failed to get share status');
  }
};
