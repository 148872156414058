import { FieldValue } from 'firebase/firestore';
import { User } from '../../../types/userTypes';

/**
 * Represents a property listing with all its details
 * @interface Listing
 * @property {number} id - Unique identifier for the listing
 * @property {string} propertyOwnerUid - Firebase UID of the property owner
 * @property {string} title - Title of the listing
 * @property {number} price - Price of the property
 * @property {number} [size] - Size of the property in square meters/feet (optional)
 * @property {string} [virtualTourUrl] - URL to virtual tour if available (optional)
 * @property {string} location - Location of the property
 * @property {PropertyType} type - Type of property (e.g., Apartment, House)
 * @property {number} bedrooms - Number of bedrooms
 * @property {number} bathrooms - Number of bathrooms
 * @property {string[]} images - Array of image URLs
 * @property {User} user - User object containing property owner details
 * @property {Status} status - Current status of the listing
 * @property {AdType} [adType] - Type of advertisement (optional)
 * @property {string | FieldValue} dateAdded - Date when listing was added
 * @property {string} description - Detailed description of the property
 * @property {string[]} amenities - Array of available amenities
 * @property {ListingIntent} listingIntent - Purpose of listing (sale/rent/share)
 * @property {Date} availableFrom - Date when property becomes available
 * @property {Date} expirationDate - Date when listing expires
 * @property {string} berRating - Building Energy Rating
 * @property {number} propertyViews - Number of times listing has been viewed
 * @property {string} [videoUrl] - URL to property video if available (optional)
 * @property {boolean} isExternalListing - Whether listing is from external source
 * @property {string} [externalListingUrl] - URL to external listing if applicable (optional)
 */

/**
 * Status of the property listing
 * @typedef {'active' | 'pending' | 'inactive' | 'all'} Status
 */

/**
 * Type of advertisement for the property listing
 * @typedef {'premium' | 'normal' | 'pro'} AdType
 */

/**
 * Purpose of the property listing
 * @typedef {'sale' | 'rent' | 'share'} ListingIntent
 */

/**
 * Array of possible property types
 * @constant {readonly string[]}
 */

/**
 * Type of property
 * @typedef {('Any' | 'Apartment' | 'Room' | 'House' | 'Studio' | 'Duplex' | 'Townhouse')} PropertyType
 */

/**
 * Array of possible Building Energy Ratings
 * @constant {string[]}
 */

/**
 * Array of possible amenities
 * @constant {string[]}
 */

/**
 * Type of amenity
 * @typedef {('Wi-Fi' | 'Parking' | 'Gym' | 'Pool' | 'Elevator' | 'Furnished' | 'Balcony' | 'Garden' | 'Pet-friendly' | 'Air Conditioning' | 'Washer/Dryer' | 'Dishwasher' | 'Security System' | 'Central Heating' | 'Storage Space')} Amenity
 */

/**
 * Checks if the status is 'active'
 * @function
 * @param {Status} status - The status to check
 * @returns {boolean} True if the status is 'active', otherwise false
 */

/**
 * Checks if the status is 'pending'
 * @function
 * @param {Status} status - The status to check
 * @returns {boolean} True if the status is 'pending', otherwise false
 */

/**
 * Checks if the status is 'inactive'
 * @function
 * @param {Status} status - The status to check
 * @returns {boolean} True if the status is 'inactive', otherwise false
 */

/**
 * Checks if the ad type is 'premium'
 * @function
 * @param {AdType} adType - The ad type to check
 * @returns {boolean} True if the ad type is 'premium', otherwise false
 */

/**
 * Checks if the ad type is 'normal'
 * @function
 * @param {AdType} adType - The ad type to check
 * @returns {boolean} True if the ad type is 'normal', otherwise false
 */

/**
 * Checks if the ad type is 'pro'
 * @function
 * @param {AdType} adType - The ad type to check
 * @returns {boolean} True if the ad type is 'pro', otherwise false
 */
interface Listing {
  id: number;
  propertyOwnerUid: string;
  title: string;
  price: number;
  size?: number;
  virtualTourUrl?: string;
  location: string;
  type: PropertyType;
  bedrooms: number;
  bathrooms: number;
  images: string[];
  user: User;
  status: Status;
  adType?: AdType;
  dateAdded: string | FieldValue;
  description: string;
  amenities: string[];
  listingIntent: ListingIntent;
  availableFrom: Date;
  expirationDate: Date;
  berRating: string;
  propertyViews: number;
  videoUrl?: string;
  isExternalListing: boolean;
  externalListingUrl?: string;
}
export type Status = 'active' | 'pending' | 'inactive' | 'all';
export type AdType = 'premium' | 'normal' | 'pro';

export type ListingIntent = 'sale' | 'rent' | 'share';

export const propertyTypes = [
  'Any',
  'Apartment',
  'Room',
  'House',
  'Studio',
  'Duplex',
  'Townhouse'
] as const;

export type PropertyType = (typeof propertyTypes)[number];

export const berRatings = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
  'D1',
  'D2',
  'E1',
  'E2',
  'F',
  'G'
];
export const amenitiesList = [
  'Wi-Fi',
  'Parking',
  'Gym',
  'Pool',
  'Elevator',
  'Furnished',
  'Balcony',
  'Garden',
  'Pet-friendly',
  'Air Conditioning',
  'Washer/Dryer',
  'Dishwasher',
  'Security System',
  'Central Heating',
  'Storage Space'
];

export type Amenity = (typeof amenitiesList)[number];

export function isActiveStatus(status: Status): status is 'active' {
  return status === 'active';
}

export function isPendingStatus(status: Status): status is 'pending' {
  return status === 'pending';
}

export function isInactiveStatus(status: Status): status is 'inactive' {
  return status === 'inactive';
}

// Type Guard for Specific AdType Values
export function isPremiumAd(adType: AdType): adType is 'premium' {
  return adType === 'premium';
}

export function isNormalAd(adType: AdType): adType is 'normal' {
  return adType === 'normal';
}

export function isProAd(adType: AdType): adType is 'pro' {
  return adType === 'pro';
}

export type { Listing };
