// src/pages/CreateListingPage.tsx

import React, { useEffect, useRef, useState } from 'react';
import {
  useForm,
  Controller,
  useFieldArray,
  SubmitHandler
} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion, AnimatePresence } from 'framer-motion';
import {
  HomeIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
  ClipboardIcon,
  CalendarIcon,
  StarIcon,
  PhotoIcon,
  PlusCircleIcon,
  PencilIcon,
  MinusCircleIcon
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon, DocumentDuplicateIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { insertDocument, queryDocuments } from '../../utility/firebaseHelpers';
import {
  amenitiesList,
  berRatings,
  Listing,
  ListingIntent,
  PropertyType,
  propertyTypes
} from '../../components/property/property-card/PropertyCardTypes';
import { uploadMultipleImages } from '../../utility/firebaseStorageHelpers';
import { generateNumericPropertyId } from '../../utility/property-utilities/generateNumericPropertyId';
import { serverTimestamp, where } from 'firebase/firestore';
import { useAuth } from '../../auth/firebaseAuth';
import { detectDiscriminatoryContent } from '../../utility/property-utilities/detectDiscriminatoryContent';
import { locations } from '../../data/locations';
import EmailVerificationRequired from '../../components/email-verification/EmailVerificationRequired';
import PropertyCard from '../../components/property/property-card/PropertyCard';
import { getRandomUser } from '../../mocks/users';
import { User } from '../../types/userTypes';
import { EyeIcon } from 'lucide-react';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';
import SocialProof from '../../components/social-proof/SocialProof';
import { getTemplateNames, propertyTemplates } from '../../data/propertyTemplates';
import IdentityVerificationForm from '../../components/identity-verification-form/IdentityVerificationForm';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';

// Define Interfaces
interface UnitFormData {
  title: string;
  price: number;
}

interface CreateListingFormData {
  propertyId?: number;
  title: string;
  type: PropertyType;
  price: number | undefined;
  size: number | undefined;
  location: string;
  virtualTourUrl: string;
  bedrooms: number;
  bathrooms: number;
  description: string;
  amenities: string[];
  availableFrom: Date;
  berRating: string;
  images: FileList | undefined;
  videoUrl?: string;
}

interface SingleListingFormData extends CreateListingFormData {
  units?: never;
}

interface GroupedListingFormData extends CreateListingFormData {
  units: UnitFormData[];
}

type ListingFormData = SingleListingFormData | GroupedListingFormData;

const usePreviewImages = (files: FileList | null) => {
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  useEffect(() => {
    // Clean up previous URLs to prevent memory leaks
    const cleanup = () => {
      previewUrls.forEach((url) => URL.revokeObjectURL(url));
    };

    // Convert FileList to Array and create URLs
    if (files) {
      const fileArray = Array.from(files);
      const urls = fileArray.map((file) => URL.createObjectURL(file));
      setPreviewUrls(urls);
    } else {
      setPreviewUrls([]);
    }

    // Cleanup when component unmounts or files change
    return cleanup;
  }, [files]);

  return previewUrls;
};

const CreateListingPage: React.FC = () => {
  const { userProfile, currentUser } = useAuth();
  const [listingType, setListingType] = useState<'single' | 'grouped' | null>(
    null
  );
  const [step, setStep] = useState(0);
  const [showTemplates, setShowTemplates] = useState(false);
  const [listingIntent, setListingIntent] = useState<ListingIntent>('rent');
  const [detectedIssues, setDetectedIssues] = useState<string[]>([]);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storedImages, setStoredImages] = useState<FileList | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const templateSectionRef = useRef<HTMLDivElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [locationInput, setLocationInput] = useState('');
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const previewUrls = usePreviewImages(storedImages);
  const navigate = useNavigate();
  const analytics = useAnalyticsWithConsent();

  analytics.trackPixelPageView();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
    setValue,
    clearErrors,
    setError
  } = useForm<ListingFormData>({
    mode: 'onTouched', // Changed from 'onChange' to 'onBlur'
    reValidateMode: 'onChange', // Similarly updated
    defaultValues: {
      // For Single Listing
      title: '',
      type: 'Apartment',
      price: undefined,
      location: '',
      virtualTourUrl: '',
      bedrooms: 1,
      size: undefined,
      bathrooms: 1,
      description: '',
      amenities: [],
      availableFrom: new Date(),
      berRating: '',
      images: {} as FileList,
      videoUrl: '',
      // For Grouped Listing
      units: [{ title: '', price: undefined }]
    }
  });

  const applyTemplate = (templateId: string) => {
    const template = propertyTemplates[templateId];

    // Set form values based on template
    setValue('title', template.title);
    setValue('type', template.type);
    setValue('description', template.description);
    setValue('bedrooms', template.bedrooms);
    setValue('bathrooms', template.bathrooms);
    setValue('amenities', template.amenities);
    setValue('size', template.size);

    // Move to the first step of single listing form
    setListingType('single');
    setStep(1);
  };

  // Filter suggestions based on user input
const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => {
  const { name, value } = e.target;
  setLocationInput(value); // Set local state for input value

  if (name === 'location') {
    setValue('location', value, { shouldValidate: true }); // Update form value for validation

    // Filter location suggestions
    const filteredSuggestions =
      value.trim() === ''
        ? []
        : locations.filter((loc) =>
            loc.toLowerCase().startsWith(value.toLowerCase())
          );

    setSuggestions(filteredSuggestions);
    setShowSuggestions(filteredSuggestions.length > 0);

    clearErrors('location');
  }
};

  const handleTemplateClick = () => {
    setShowTemplates(true);
    // Wait for the template section to render before scrolling
    setTimeout(() => {
      templateSectionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 100); // Small delay to ensure the section is rendered
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setStoredImages(event.target.files);
      setValue('images', event.target.files, { shouldValidate: true });
      clearErrors('images'); // Clear any existing errors
    } else {
      setStoredImages(null);
      // Instead of setting to null, we'll remove the 'images' field from the form state
      setValue('images', undefined, { shouldValidate: true });
      setError('images', {
        type: 'manual',
        message: 'At least one image is required'
      });
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion: string) => {
    setLocationInput(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    if (showSuggestions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSuggestions]);

  const title = watch('title');
  const description = watch('description');

  useEffect(() => {
    const issues = detectDiscriminatoryContent(`${title} ${description}`);
    setDetectedIssues(issues);
  }, [title, description]);

  // Add useEffect to check verification status
  useEffect(() => {
    const checkVerificationStatus = async () => {
      if (!currentUser) return;
      try {
        const verifications = await queryDocuments('identityVerification', [
          where('userId', '==', currentUser.uid),
          where('status', '==', 'approved')
        ]);
        setIsVerified(verifications.length > 0);
      } catch (err) {
        console.error('Error checking verification status:', err);
      }
    };

    checkVerificationStatus();
  }, [currentUser]);

  const priceLabel = listingIntent === 'sale' ? 'Price' : 'Price (per month)';

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'units'
  });

  const uploadImages = async (files: FileList, propertyId: number) => {
    const formData = new FormData();

    Array.from(files).forEach((file) => {
      formData.append('file', file);
    });

    try {
      const response = await fetch(
        `https://uploadimages-byx4ztggoq-uc.a.run.app?propertyId=${propertyId}`,
        {
          method: 'POST',
          body: formData,
          credentials: 'include'
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Upload failed');
      }

      const data = await response.json();
      return data.images;
    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };

  const onSubmit: SubmitHandler<ListingFormData> = async (data) => {
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true);
    setSubmitError(null);
    try {
      //Allow only user with complete profiles to create listings
      if (userProfile && storedImages) {
        // Generate a numeric property ID
        const propertyId = generateNumericPropertyId(14); // 14-digit ID

        // Upload images to Firebase Storage
        const imageUrls = await uploadMultipleImages(
          storedImages,
          `listings/${propertyId}`
        );

        //TODO: expirationDate should be added on the server to prevent users from manipulating expiration date on their device
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 3);

        const listingData: Listing = {
          id: propertyId,
          title: data.title,
          type: data.type,
          price: Number(data.price),
          location: locationInput.toLowerCase(),
          bedrooms: data.bedrooms,
          bathrooms: data.bathrooms,
          size: data.size,
          status: 'active',
          virtualTourUrl: data.virtualTourUrl,
          listingIntent: listingIntent,
          propertyOwnerUid: currentUser?.uid ?? '',
          description: data.description,
          amenities: data.amenities,
          availableFrom: data.availableFrom,
          expirationDate: expirationDate,
          berRating: data.berRating,
          images: imageUrls, // Use the uploaded image URLs
          videoUrl: data.videoUrl,
          user: userProfile,
          dateAdded: serverTimestamp(),
          propertyViews: 0,
          isExternalListing: false,
        };

        //Append data with propertyId
        data.propertyId = propertyId;
        data.location = locationInput;

        // Insert the listing into Firestore
        await insertDocument<Listing>(
          'listings',
          listingData,
          propertyId.toString()
        );

        // Navigate to the success page with the listing summary
        navigate('/listing-success', {
          state: {
            listingSummary: data
          }
        });
      }
    } catch (error) {
      console.error('Error creating listing:', error);
      setSubmitError('Failed to create listing. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const nextStep = async () => {
    // Ensure location is required
    let valid = false;
    if (listingType === 'single') {
      switch (step) {
        case 1:
          valid = await trigger(['title', 'type']);
          break;
        case 2:
          if (!locationInput.trim()) {
            setError('location', {
              type: 'manual',
              message: 'Location is required'
            });
            valid = false;
          } else {
            valid = await trigger([
              'price',
              'location',
              'bedrooms',
              'bathrooms'
            ]);
          }
          break;
        case 3:
          valid = await trigger(['description']);
          break;
        case 4:
          valid = await trigger(['availableFrom', 'berRating']);
          break;
        case 5:
          if (!storedImages || storedImages.length === 0) {
            setError('images', {
              type: 'manual',
              message: 'At least one image is required'
            });
            valid = false;
          } else {
            valid = await trigger(['images']);
          }
          break;
        default:
          valid = true;
      }
    } else if (listingType === 'grouped') {
      switch (step) {
        case 1:
          valid = await trigger(['title', 'location']);
          break;
        case 2:
          const unitTitleFields = fields.map(
            (field, index) => `units.${index}.title` as const
          );
          const unitPriceFields = fields.map(
            (field, index) => `units.${index}.price` as const
          );
          valid =
            (await trigger(unitTitleFields)) &&
            (await trigger(unitPriceFields));
          break;
        default:
          valid = true;
      }
    }

    if (valid) {
      setStep(step + 1);
    }
  };

  const prevStep = () => setStep(step - 1);
  const renderListingTypeSelection = () => (
    <motion.div
      key="listingTypeSelection"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl text-center text-gray-800 mb-8">
        How would you like to get started ?
      </h2>
      <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0 items-stretch">
        {/* Template Option Card */}
        <div className="flex-1">
          <button
            onClick={handleTemplateClick}
            className="w-full h-full bg-gradient-to-r from-indigo-400 to-indigo-600 text-white px-6 py-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300 flex flex-col items-center text-center"
          >
            <div className="bg-white bg-opacity-20 rounded-full p-4 mb-4">
              <DocumentDuplicateIcon className="w-16 h-16 text-indigo-600" />
            </div>
            <span className="font-semibold text-2xl mb-2">
              Start with a Template
            </span>
            <p className="text-sm px-2 mb-4">
              Choose from our pre-made templates for common property types. Save
              time and ensure consistent, professional listings.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5 text-green-400" />
                <span className="text-sm">Quick Setup</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5 text-green-400" />
                <span className="text-sm">Professional Descriptions</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5 text-green-400" />
                <span className="text-sm">Best Practices</span>
              </div>
            </div>
          </button>
        </div>

        <div className="text-center mb-8">
          <p className="text-gray-600">- OR -</p>
        </div>

        {/* Single Listing Option */}
        <div className="flex-1">
          <button
            onClick={() => {
              setListingType('single');
              setStep(1);
            }}
            className="w-full h-full bg-gradient-to-r from-blue-400 to-blue-600 text-white px-6 py-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300 flex flex-col items-center text-center"
          >
            {/* Main Icon with Enhanced Styling */}
            <div className="bg-white bg-opacity-20 rounded-full p-4 mb-4">
              <HomeIcon className="w-16 h-16 text-blue-600" />
            </div>
            {/* Listing Type Title */}
            <span className="font-semibold text-2xl mb-2">
              Create your own Listing
            </span>
            {/* Description */}
            <p className="text-sm px-2 mb-4">
              Perfect for individual properties like apartments or houses.
              Showcase your property with detailed information and attract
              potential tenants with ease.
            </p>
            {/* Feature Icons */}
            <div className="flex flex-wrap justify-center gap-4">
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5 text-green-400" />
                <span className="text-sm">High Visibility</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5 text-green-400" />
                <span className="text-sm">Detailed Insights</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5 text-green-400" />
                <span className="text-sm">Easy Management</span>
              </div>
            </div>
          </button>
        </div>

        {/* Grouped Listing Option */}
        {remoteConfig.isGroupedListingEnabled() && (
          <div className="flex-1">
            <button
              onClick={() => {
                setListingType('grouped');
                setStep(1);
              }}
              className="w-full h-full bg-gradient-to-r from-green-400 to-green-600 text-white px-6 py-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300 flex flex-col items-center text-center"
            >
              {/* Main Icon with Enhanced Styling */}
              <div className="bg-white bg-opacity-20 rounded-full p-4 mb-4">
                <UserGroupIcon className="w-16 h-16 text-green-600" />
              </div>
              {/* Listing Type Title */}
              <span className="font-semibold text-2xl mb-2">
                Grouped Listing
              </span>
              {/* Description */}
              <p className="text-sm px-2 mb-4">
                Ideal for multiple units under a single group, such as apartment
                complexes or office buildings. Manage and display multiple units
                efficiently, providing comprehensive details for each.
              </p>
              {/* Feature Icons */}
              <div className="flex flex-wrap justify-center gap-4">
                <div className="flex items-center space-x-2">
                  <CheckCircleIcon className="w-5 h-5 text-green-400" />
                  <span className="text-sm">Bulk Management</span>
                </div>
                <div className="flex items-center space-x-2">
                  <CheckCircleIcon className="w-5 h-5 text-green-400" />
                  <span className="text-sm">Unified Dashboard</span>
                </div>
                <div className="flex items-center space-x-2">
                  <CheckCircleIcon className="w-5 h-5 text-green-400" />
                  <span className="text-sm">Comprehensive Analytics</span>
                </div>
              </div>
            </button>
          </div>
        )}
      </div>

      {/* Template Selection Section */}
      {showTemplates && (
        <motion.div
          ref={templateSectionRef}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="mt-8"
        >
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Choose a Template
              </h3>
              <button
                onClick={() => setShowTemplates(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {Object.entries(getTemplateNames()).map(([id, name]) => {
                const template = propertyTemplates[id];
                return (
                  <button
                    key={id}
                    onClick={() => applyTemplate(id)}
                    className="p-6 border border-gray-200 rounded-lg hover:border-indigo-500 hover:shadow-md transition-all duration-300 bg-white text-left group"
                  >
                    <div className="flex flex-col space-y-3">
                      <div className="flex items-center space-x-3">
                        <DocumentDuplicateIcon className="w-8 h-8 text-indigo-500 group-hover:text-indigo-600" />
                        <div>
                          <h4 className="font-semibold text-gray-900">
                            {name}
                          </h4>
                          <p className="text-sm text-gray-500">
                            {template.bedrooms} bed, {template.bathrooms} bath •{' '}
                            {template.size}m²
                          </p>
                        </div>
                      </div>
                      <div className="text-sm text-gray-600">
                        <p className="line-clamp-2">
                          {template.description.split('\n')[0]}
                        </p>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {template.amenities.slice(0, 3).map((amenity) => (
                          <span
                            key={amenity}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                          >
                            {amenity}
                          </span>
                        ))}
                        {template.amenities.length > 3 && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            +{template.amenities.length - 3} more
                          </span>
                        )}
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </motion.div>
      )}

      <SocialProof userType="landlord" />
    </motion.div>
  );

  const renderGroupedListingForm = () => (
    <motion.div
      key="groupedListingForm"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6">Grouped Listing Details</h2>

      {/* Group Title */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">
          Group Title
        </label>
        <input
          {...register('title', { required: 'Group title is required' })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.title ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="e.g., Family Apartments"
        />
        {errors.title && (
          <span className="text-red-500 text-sm">{errors.title.message}</span>
        )}
      </div>

      {/* Location */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">Location</label>
        <input
          {...register('location', { required: 'Location is required' })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.location ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="e.g., Dublin 2"
        />
        {errors.location && (
          <span className="text-red-500 text-sm">
            {errors.location.message}
          </span>
        )}
      </div>

      {/* Property Type */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">
          Property Type
        </label>
        <select
          {...register('type', { required: 'Property type is required' })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.type ? 'border-red-500' : 'border-gray-300'
          }`}
        >
          <option value="">Select a property type</option>
          {propertyTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        {errors.type && (
          <span className="text-red-500 text-sm">{errors.type.message}</span>
        )}
      </div>

      {/* Description */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">
          Tell Us About Your Property
        </label>
        <textarea
          {...register('description', {
            required: 'Description is required',
            minLength: {
              value: 50,
              message: 'Description must be at least 50 characters'
            }
          })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.description ? 'border-red-500' : 'border-gray-300'
          }`}
          rows={5}
          placeholder="Highlight what makes your property stand out..."
        />
        {errors.description && (
          <span className="text-red-500 text-sm">
            {errors.description.message}
          </span>
        )}
      </div>

      {/* Amenities */}
      <div>
        <label className="block mb-4 font-medium text-gray-700">
          Amenities
        </label>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {amenitiesList.map((amenity) => (
            <label key={amenity} className="flex items-center text-gray-700">
              <input
                type="checkbox"
                value={amenity}
                {...register('amenities')}
                className="mr-2 h-5 w-5 text-blue-500 focus:ring-blue-500"
              />
              {amenity}
            </label>
          ))}
        </div>
      </div>

      {/* BER Rating */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">
          BER Rating
        </label>
        <select
          {...register('berRating', { required: 'BER rating is required' })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.berRating ? 'border-red-500' : 'border-gray-300'
          }`}
        >
          <option value="">Select a BER rating</option>
          {berRatings.map((rating) => (
            <option key={rating} value={rating}>
              {rating}
            </option>
          ))}
        </select>
        {errors.berRating && (
          <span className="text-red-500 text-sm">
            {errors.berRating.message}
          </span>
        )}
      </div>

      {/* Available From Date */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">
          Available From
        </label>
        <Controller
          control={control}
          name="availableFrom"
          rules={{
            required: 'Available from date is required',
            validate: {
              notPast: (value) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                if (value) {
                  return value >= today || 'Date cannot be in the past';
                }
                return 'Date is required';
              }
            }
          }}
          render={({ field }) => (
            <DatePicker
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                errors.availableFrom ? 'border-red-500' : 'border-gray-300'
              }`}
              minDate={new Date()}
              placeholderText="Select a date"
              dateFormat="dd/MM/yyyy"
            />
          )}
        />
        {errors.availableFrom && (
          <span className="text-red-500 text-sm">
            {errors.availableFrom.message}
          </span>
        )}
      </div>

      {/* Images */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">Images</label>
        <input
          type="file"
          multiple
          accept="image/jpeg,image/png,image/gif"
          {...register('images', {
            required: 'At least one image is required',
            validate: {
              isImage: (files: FileList | undefined) => {
                if (files && files.length === 0) {
                  return 'At least one image is required';
                }
                const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                if (files) {
                  for (let i = 0; i < files.length; i++) {
                    if (!validTypes.includes(files[i].type)) {
                      return 'Only JPEG, PNG, and GIF files are allowed';
                    }
                  }
                }

                return true;
              }
            }
          })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.images ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {errors.images && (
          <span className="text-red-500 text-sm">{errors.images.message}</span>
        )}
      </div>

      {/* Video URL */}
      <div>
        <label className="block mb-2 font-medium text-gray-700">
          Video URL (optional)
        </label>
        <input
          {...register('videoUrl', {
            pattern: {
              value: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
              message: 'Enter a valid YouTube URL'
            }
          })}
          className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
            errors.videoUrl ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="https://www.youtube.com/watch?v=example"
        />
        {errors.videoUrl && (
          <span className="text-red-500 text-sm">
            {errors.videoUrl.message}
          </span>
        )}
      </div>

      {/* Units Section */}
      <div>
        <h3 className="text-xl font-semibold mb-4">Units</h3>
        {fields.map((field, index) => (
          <div key={field.id} className="mb-6 p-4 border rounded-lg">
            <h4 className="text-lg font-medium mb-2">Unit {index + 1}</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Title
                </label>
                <input
                  {...register(`units.${index}.title` as const, {
                    required: 'Unit title is required',
                    maxLength: {
                      value: 100,
                      message: 'Title cannot exceed 100 characters'
                    }
                  })}
                  className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.units &&
                    Array.isArray(errors.units) &&
                    errors.units[index]?.title
                      ? 'border-red-500'
                      : 'border-gray-300'
                  }`}
                  placeholder="e.g., Master Bedroom"
                />
                {errors.units &&
                  Array.isArray(errors.units) &&
                  errors.units[index]?.title && (
                    <span className="text-red-500 text-sm">
                      {errors.units[index].title?.message}
                    </span>
                  )}
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Price (€ per month)
                </label>
                <input
                  type="number"
                  {...register(`units.${index}.price` as const, {
                    required: 'Price is required',
                    min: { value: 1, message: 'Price must be at least €1' }
                  })}
                  className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.units &&
                    Array.isArray(errors.units) &&
                    errors.units[index]?.price
                      ? 'border-red-500'
                      : 'border-gray-300'
                  }`}
                  placeholder="e.g., 800"
                />
                {errors.units &&
                  Array.isArray(errors.units) &&
                  errors.units[index]?.price && (
                    <span className="text-red-500 text-sm">
                      {errors.units[index].price?.message}
                    </span>
                  )}
              </div>
            </div>
            {fields.length > 1 && (
              <button
                type="button"
                onClick={() => remove(index)}
                className="mt-2 flex items-center text-red-500 hover:text-red-700"
              >
                <MinusCircleIcon className="w-5 h-5 mr-1" />
                Remove Unit
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={() => append({ title: '', price: 0 })} // Provide default values
          className="flex items-center text-blue-500 hover:text-blue-700"
        >
          <PlusCircleIcon className="w-5 h-5 mr-1" />
          Add Unit
        </button>
      </div>
    </motion.div>
  );

  // Implementations for Single Listing Steps

  const renderSingleStep1 = () => (
    <motion.div
      key="singleStep1"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6 flex items-center">
        <HomeIcon className="w-6 h-6 mr-2 text-blue-500" />
        Basic Information
      </h2>
      <div className="space-y-6">
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            How would you like to name this property?
          </label>
          <input
            {...register('title', {
              required: 'Title is required',
              maxLength: {
                value: 100,
                message: 'Title cannot exceed 100 characters'
              },
              pattern: {
                value: /^[A-Za-z0-9\s-]+$/,
                message: 'Title must be alphanumeric'
              }
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.title ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="e.g., Spacious Studio"
          />
          {errors.title && (
            <span className="text-red-500 text-sm">{errors.title.message}</span>
          )}
          {detectedIssues.length > 0 && (
            <div className="bg-red-100 p-4 rounded text-red-600 mb-4 mt-4">
              <p className="font-bold">Warning:</p>
              <p>
                This title may contain discriminatory content against our
                guidelines:
              </p>
              <ul className="list-disc list-inside mt-2">
                {detectedIssues.map((issue, index) => (
                  <li key={index}>{issue}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            What type of property are you listing
          </label>
          <select
            {...register('type', {
              required: 'Property type is required'
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.type ? 'border-red-500' : 'border-gray-300'
            }`}
          >
            <option value="">Select a property type</option>
            {propertyTypes.filter((item) => item !== 'Any').map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          {errors.type && (
            <span className="text-red-500 text-sm">{errors.type.message}</span>
          )}
        </div>

        <label className="block mb-2 font-medium text-gray-700">
          Would this property be listed as for rent or sale
        </label>
        <div className="flex flex-col sm:flex-row sm:space-x-6 mb-6">
          <label className="inline-flex items-center mb-2 sm:mb-0">
            <input
              type="radio"
              value="rent"
              checked={listingIntent === 'rent'}
              onChange={() => setListingIntent('rent')}
              className="hidden"
            />
            <span
              className={`
      px-4 py-2 rounded-full text-sm font-medium
      border-2 cursor-pointer transition-all duration-200 ease-in-out
      ${
        listingIntent === 'rent'
          ? 'bg-blue-500 text-white border-blue-500'
          : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
      }
      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
    `}
            >
              Rent
            </span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="sale"
              checked={listingIntent === 'sale'}
              onChange={() => setListingIntent('sale')}
              className="hidden"
            />
            <span
              className={`
      px-4 py-2 rounded-full text-sm font-medium
      border-2 cursor-pointer transition-all duration-200 ease-in-out
      ${
        listingIntent === 'sale'
          ? 'bg-blue-500 text-white border-blue-500'
          : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
      }
      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
    `}
            >
              Sale
            </span>
          </label>
        </div>
      </div>
    </motion.div>
  );

  const renderSingleStep2 = () => (
    <motion.div
      key="singleStep2"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6 flex items-center">
        <CurrencyDollarIcon className="w-6 h-6 mr-2 text-blue-500" />
        Property Details
      </h2>
      <div className="space-y-6">
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            {priceLabel}
          </label>
          <input
            type="number"
            {...register('price', {
              required: 'Price is required',
              min: { value: 1, message: 'Price must be at least €1' },
              valueAsNumber: true,
              onChange: (e) => {
                const value = e.target.valueAsNumber;
                if (value === 0 || isNaN(value)) {
                  setValue('price', undefined);
                  e.target.value = '';
                }
              }
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.price ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="e.g., 1200"
          />
          {errors.price && (
            <span className="text-red-500 text-sm">{errors.price.message}</span>
          )}
        </div>

        <div>
          <label className="block mb-2 font-medium text-gray-700">
            Listing size in sq meters (Optional)
          </label>
          <input
            type="number"
            {...register('size', {
              min: { value: 1, message: 'Size must be at least 1 sq meter' },
              valueAsNumber: true,
              onChange: (e) => {
                const value = e.target.valueAsNumber;
                if (value === 0 || isNaN(value)) {
                  setValue('size', undefined);
                  e.target.value = '';
                }
              }
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.size ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="e.g., 110"
          />
          {errors.size && (
            <span className="text-red-500 text-sm">{errors.size.message}</span>
          )}
        </div>
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            Location
          </label>
          <input
            aria-autocomplete="list"
            aria-controls="location-suggestions"
            autoComplete="off"
            type="text"
            name="location"
            value={locationInput}
            onChange={handleInputChange}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.location ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="e.g., Dublin City Centre"
          />
          {/* Suggestions Dropdown */}
          {showSuggestions && suggestions.length > 0 && (
            <ul
              ref={suggestionsRef}
              id="location-suggestions"
              role="listbox"
              className="absolute z-10 w-full bg-white border border-gray-200 rounded-b-lg max-h-60 overflow-y-auto shadow-lg"
            >
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  role="option"
                  aria-selected={false}
                  className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          {errors.location && (
            <span className="text-red-500 text-sm">
              {errors.location.message}
            </span>
          )}
        </div>
        <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
          <div className="flex-1">
            <label className="block mb-2 font-medium text-gray-700">
              <UserGroupIcon className="w-5 h-5 inline-block mr-1 text-blue-500" />
              Bedrooms
            </label>
            <input
              type="number"
              {...register('bedrooms', {
                required: 'Number of bedrooms is required',
                min: { value: 1, message: 'At least 1 bedroom required' },
                validate: {
                  integer: (value) =>
                    Number.isInteger(Number(value)) || 'Must be an integer'
                }
              })}
              className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                errors.bedrooms ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="e.g., 2"
            />
            {errors.bedrooms && (
              <span className="text-red-500 text-sm">
                {errors.bedrooms.message}
              </span>
            )}
          </div>
          <div className="flex-1">
            <label className="block mb-2 font-medium text-gray-700">
              Bathrooms
            </label>
            <input
              type="number"
              {...register('bathrooms', {
                required: 'Number of bathrooms is required',
                min: {
                  value: 1,
                  message: 'At least 1 bathroom required'
                },
                validate: {
                  integer: (value) =>
                    Number.isInteger(Number(value)) || 'Must be an integer'
                }
              })}
              className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                errors.bathrooms ? 'border-red-500' : 'border-gray-300'
              }`}
              placeholder="e.g., 1"
            />
            {errors.bathrooms && (
              <span className="text-red-500 text-sm">
                {errors.bathrooms.message}
              </span>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );

  const renderSingleStep3 = () => (
    <motion.div
      key="singleStep3"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6 flex items-center">
        <ClipboardIcon className="w-6 h-6 mr-2 text-blue-500" />
        Description & Amenities
      </h2>
      <div className="space-y-6">
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            Tell Us About Your Property
          </label>
          <textarea
            {...register('description', {
              required: 'Description is required',
              minLength: {
                value: 50,
                message: 'Description must be at least 50 characters'
              }
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.description ? 'border-red-500' : 'border-gray-300'
            }`}
            rows={5}
            placeholder="Highlight what makes your property stand out..."
          />
          {errors.description && (
            <span className="text-red-500 text-sm">
              {errors.description.message}
            </span>
          )}

          {detectedIssues.length > 0 && (
            <div className="bg-red-100 p-4 rounded text-red-600 mb-4 mt-4">
              <p className="font-bold">Warning:</p>
              <p>
                The description may contain discriminatory content against our
                guidelines:
              </p>
              <ul className="list-disc list-inside mt-2">
                {detectedIssues.map((issue, index) => (
                  <li key={index}>{issue}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div>
          <label className="block mb-4 font-medium text-gray-700">
            Amenities
          </label>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {amenitiesList.map((amenity) => (
              <label key={amenity} className="flex items-center text-gray-700">
                <input
                  type="checkbox"
                  value={amenity}
                  {...register('amenities')}
                  className="mr-2 h-5 w-5 text-blue-500 focus:ring-blue-500"
                />
                {amenity}
              </label>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );

  const renderSingleStep4 = () => (
    <motion.div
      key="singleStep4"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6 flex items-center">
        <CalendarIcon className="w-6 h-6 mr-2 text-blue-500" />
        Additional Information
      </h2>
      <div className="space-y-6">
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            Available From
          </label>
          <Controller
            control={control}
            name="availableFrom"
            rules={{
              required: 'Available from date is required',
              validate: {
                notPast: (value) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  if (value) {
                    return value >= today || 'Date cannot be in the past';
                  }
                  return 'Date is required';
                }
              }
            }}
            render={({ field }) => (
              <DatePicker
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                  errors.availableFrom ? 'border-red-500' : 'border-gray-300'
                }`}
                minDate={new Date()}
                placeholderText="Select a date"
                dateFormat="dd/MM/yyyy"
              />
            )}
          />
          {errors.availableFrom && (
            <span className="text-red-500 text-sm">
              {errors.availableFrom.message}
            </span>
          )}
        </div>
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            Virtual tour URL (optional)
          </label>
          <input
            {...register('virtualTourUrl', {
              pattern: {
                value:
                  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w\-.~:?#[\]@!$&'()*+,;=]*)*\/?$/,
                message: 'Enter a valid virtual tour URL'
              }
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.videoUrl ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="https://www.virtual-tourwebsite.com/"
          />
          {errors.virtualTourUrl && (
            <span className="text-red-500 text-sm">
              {errors.virtualTourUrl.message}
            </span>
          )}
        </div>
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            <StarIcon className="w-5 h-5 inline-block mr-1 text-blue-500" />
            BER Rating
          </label>
          <select
            {...register('berRating', {
              required: 'BER rating is required'
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.berRating ? 'border-red-500' : 'border-gray-300'
            }`}
          >
            <option value="">Select a BER rating</option>
            {berRatings.map((rating) => (
              <option key={rating} value={rating}>
                {rating}
              </option>
            ))}
          </select>
          {errors.berRating && (
            <span className="text-red-500 text-sm">
              {errors.berRating.message}
            </span>
          )}
        </div>
      </div>
    </motion.div>
  );

  const renderSingleStep5 = () => (
    <motion.div
      key="singleStep5"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6 flex items-center">
        <PhotoIcon className="w-6 h-6 mr-2 text-blue-500" />
        Media
      </h2>
      <div className="space-y-6">
        <div>
          <label className="block mb-2 font-medium text-gray-700">Images</label>
          <input
            type="file"
            multiple
            accept="image/jpeg,image/png,image/gif"
            onChange={handleImageChange}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.images ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {storedImages && (
            <p className="mt-2 text-sm text-gray-600">
              {storedImages.length} file(s) selected
            </p>
          )}
          {errors.images && (
            <span className="text-red-500 text-sm">
              {errors.images.message}
            </span>
          )}
        </div>
        <div>
          <label className="block mb-2 font-medium text-gray-700">
            Video URL (optional)
          </label>
          <input
            {...register('videoUrl', {
              pattern: {
                value: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
                message: 'Enter a valid YouTube URL'
              }
            })}
            className={`w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
              errors.videoUrl ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder="https://www.youtube.com/watch?v=example"
          />
          {errors.videoUrl && (
            <span className="text-red-500 text-sm">
              {errors.videoUrl.message}
            </span>
          )}
        </div>
      </div>
    </motion.div>
  );

  const renderSingleReview = () => {
    const randomUser = getRandomUser();

    // Create a preview listing object from form data
    const previewListing: Listing = {
      id: 0, // Temporary ID for preview
      title: watch('title') || 'Your Property Title',
      type: watch('type') || 'Property Type',
      price: Number(watch('price')) || 0,
      size: Number(watch('size')) || 0,
      location: locationInput || 'Property Location',
      bedrooms: Number(watch('bedrooms')) || 0,
      bathrooms: Number(watch('bathrooms')) || 0,
      description: watch('description') || 'Property Description',
      amenities: watch('amenities') || [],
      availableFrom: watch('availableFrom') || new Date(),
      virtualTourUrl: watch('virtualTourUrl') || '',
      berRating: watch('berRating') || 'N/A',
      images: previewUrls,
      videoUrl: watch('videoUrl') || '',
      listingIntent: 'rent',
      // Add any other required fields with fallback values
      propertyOwnerUid: '',
      user: randomUser as User,
      status: 'all',
      dateAdded: '',
      expirationDate: new Date(),
      propertyViews: 0,
      isExternalListing: false
    };

    return (
      <motion.div
        key="singleReview"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        className="space-y-6"
      >
        <h2 className="text-2xl font-semibold mb-6 flex items-center">
          <ClipboardIcon className="w-6 h-6 mr-2 text-blue-500" />
          Review & Submit
        </h2>

        {/* Preview Card */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
            <EyeIcon className="h-6 w-6  text-blue-500 mr-2" />
            Preview – Here's how your listing will appear once it's live!
          </h3>
          <div className="bg-gray-100 p-6 rounded-lg">
            <PropertyCard
              listing={previewListing}
              isBookmarked={false}
              onBookmarkToggle={() => {}}
              onShare={() => {}}
              isPreview={true}
            />
          </div>
        </div>

        <p className="text-gray-700">
          Missed Something? Easily Head Back and Make Your Changes
        </p>

        <div className="space-y-6">
          {[
            { label: 'Property Title', value: watch('title'), step: 1 },
            { label: 'Property Type', value: watch('type'), step: 1 },
            { label: 'Price', value: `€${watch('price')}`, step: 2 },
            { label: 'Size', value: `${watch('size')} m²`, step: 2 },
            { label: 'Location', value: locationInput, step: 2 },
            { label: 'Bedrooms', value: watch('bedrooms'), step: 2 },
            { label: 'Bathrooms', value: watch('bathrooms'), step: 2 },
            { label: 'Description', value: watch('description'), step: 3 },
            {
              label: 'Amenities',
              value: watch('amenities')?.length
                ? watch('amenities').join(', ')
                : 'None',
              step: 3
            },
            {
              label: 'Available From',
              value: watch('availableFrom')?.toLocaleDateString(),
              step: 4
            },
            {
              label: 'Virtual tour URL',
              value: watch('virtualTourUrl'),
              step: 4
            },
            { label: 'BER Rating', value: watch('berRating'), step: 4 },
            {
              label: 'Images',
              value: storedImages
                ? `${storedImages.length} file(s) selected`
                : 'No images selected',
              step: 5
            },
            {
              label: 'Video URL',
              value: watch('videoUrl') || 'No video URL provided',
              step: 5
            }
          ].map((item, index) => (
            <div
              key={index}
              className="bg-gray-100 p-4 rounded-lg flex justify-between items-start shadow-sm border border-gray-200"
            >
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {item.label}
                </h3>
                <p className="text-gray-600">{item.value}</p>
              </div>
              <button
                type="button"
                onClick={() => setStep(item.step)}
                className="text-blue-500 hover:text-blue-700 transition"
                aria-label={`Edit ${item.label.toLowerCase()}`}
              >
                <PencilIcon className="w-5 h-5" />
              </button>
            </div>
          ))}
        </div>
      </motion.div>
    );
  };

  const renderGroupedReview = () => (
    <motion.div
      key="groupedReview"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -50 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-semibold mb-6 flex items-center">
        <ClipboardIcon className="w-6 h-6 mr-2 text-blue-500" />
        Review & Submit
      </h2>
      <p className="text-gray-700">
        Missed anything ? Easily go back and make your changes
      </p>
      <div className="space-y-4">
        {/* Group Title */}
        <div>
          <h3 className="text-lg font-medium">Group Title:</h3>
          <p className="text-gray-700">{watch('title')}</p>
        </div>

        {/* Location */}
        <div>
          <h3 className="text-lg font-medium">Location:</h3>
          <p className="text-gray-700">{watch('location')}</p>
        </div>

        {/* Property Type */}
        <div>
          <h3 className="text-lg font-medium">Property Type:</h3>
          <p className="text-gray-700">{watch('type')}</p>
        </div>

        {/* Description */}
        <div>
          <h3 className="text-lg font-medium">Description:</h3>
          <p className="text-gray-700">{watch('description')}</p>
        </div>

        {/* Amenities */}
        <div>
          <h3 className="text-lg font-medium">Amenities:</h3>
          <p className="text-gray-700">
            {watch('amenities')?.length
              ? watch('amenities')?.join(', ')
              : 'None'}
          </p>
        </div>

        {/* BER Rating */}
        <div>
          <h3 className="text-lg font-medium">BER Rating:</h3>
          <p className="text-gray-700">{watch('berRating')}</p>
        </div>

        {/* Available From Date */}
        <div>
          <h3 className="text-lg font-medium">Available From:</h3>
          <p className="text-gray-700">
            {watch('availableFrom')?.toLocaleDateString()}
          </p>
        </div>

        {/* Images */}
        <div>
          <h3 className="text-lg font-medium">Images:</h3>
          <p className="text-gray-700">
            {watch('images')?.length
              ? `${watch('images')?.length} file(s) uploaded`
              : 'No images uploaded'}
          </p>
        </div>

        {/* Video URL */}
        <div>
          <h3 className="text-lg font-medium">Video URL:</h3>
          <p className="text-gray-700">
            {watch('videoUrl') || 'No video URL provided'}
          </p>
        </div>

        {/* Units */}
        <div>
          <h3 className="text-lg font-medium">Units:</h3>
          {watch('units')?.map((unit, index) => (
            <div key={index} className="ml-4">
              <p>
                <strong>Unit {index + 1}:</strong> {unit.title} - €{unit.price}
              </p>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );

  const renderStep = () => {
    if (step === 0) {
      return renderListingTypeSelection();
    }

    if (!listingType) {
      // This should not happen as step > 0 implies listingType is selected
      return (
        <div className="text-center text-red-500">
          Error: Listing type not selected.
        </div>
      );
    }

    if (listingType === 'single') {
      switch (step) {
        case 1:
          return renderSingleStep1();
        case 2:
          return renderSingleStep2();
        case 3:
          return renderSingleStep3();
        case 4:
          return renderSingleStep4();
        case 5:
          return renderSingleStep5();
        case 6:
          return renderSingleReview();
        default:
          return null;
      }
    } else if (listingType === 'grouped') {
      switch (step) {
        case 1:
          return renderGroupedListingForm();
        case 2:
          return renderGroupedReview();
        default:
          return null;
      }
    }

    return null;
  };

  // Check email verification before letting users create a listing
  if (
    remoteConfig.requireEmailVerificationToCreateListing() &&
    userProfile &&
    !userProfile.accountVerification.emailVerified
  ) {
    return (
      <EmailVerificationRequired
        userEmail={userProfile.email}
        userId={userProfile.id}
        userName={userProfile.name}
      />
    );
  }

  if (!isVerified && remoteConfig.requireIdentityVerification()) {
   return <IdentityVerificationForm />;
  }

  return (
    <div className="py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-8 text-center">
          Ready to List Your Property? Start Now – It’s Free!
        </h1>
        {step > 0 && (
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              {listingType === 'single'
                ? [1, 2, 3, 4, 5, 6].map((s) => (
                    <div key={s} className="flex-1">
                      <div
                        className={`w-10 h-10 mx-auto rounded-full text-lg font-semibold flex items-center justify-center ${
                          s === step
                            ? 'bg-blue-500 text-white'
                            : s < step
                              ? 'bg-green-500 text-white'
                              : 'bg-gray-300 text-gray-600'
                        }`}
                      >
                        {s}
                      </div>
                      {s < 6 && (
                        <div
                          className={`h-1 mx-auto w-full ${
                            s < step ? 'bg-green-500' : 'bg-gray-300'
                          }`}
                        ></div>
                      )}
                    </div>
                  ))
                : [1, 2].map((s) => (
                    <div key={s} className="flex-1">
                      <div
                        className={`w-10 h-10 mx-auto rounded-full text-lg font-semibold flex items-center justify-center ${
                          s === step
                            ? 'bg-blue-500 text-white'
                            : s < step
                              ? 'bg-green-500 text-white'
                              : 'bg-gray-300 text-gray-600'
                        }`}
                      >
                        {s}
                      </div>
                      {s < 2 && (
                        <div
                          className={`h-1 mx-auto w-full ${
                            s < step ? 'bg-green-500' : 'bg-gray-300'
                          }`}
                        ></div>
                      )}
                    </div>
                  ))}
            </div>
            <div className="flex justify-between text-sm font-medium text-gray-600">
              {listingType === 'single' ? (
                <>
                  <span>Basic</span>
                  <span>Details</span>
                  <span>Description</span>
                  <span>Additional</span>
                  <span>Media</span>
                  <span>Review</span>
                </>
              ) : (
                <>
                  <span>Group Details</span>
                  <span>Units</span>
                </>
              )}
            </div>
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-md rounded-lg p-8"
        >
          <AnimatePresence mode="wait">{renderStep()}</AnimatePresence>
          <div className="mt-8 flex justify-between">
            {step > 0 && (
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-400 transition duration-300"
              >
                Previous
              </button>
            )}
            {((listingType === 'single' && step < 6) ||
              (listingType === 'grouped' && step < 2)) && (
              <button
                type="button"
                onClick={nextStep}
                className="ml-auto bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Next
              </button>
            )}
            {((listingType === 'single' && step === 6) ||
              (listingType === 'grouped' && step === 2)) && (
              <>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`ml-auto px-6 py-3 rounded-lg transition duration-300 relative
    ${
      isSubmitting
        ? 'bg-green-400 cursor-not-allowed'
        : 'bg-green-500 hover:bg-green-600'
    }
    text-white`}
                >
                  <span
                    className={`${isSubmitting ? 'opacity-0' : 'opacity-100'}`}
                  >
                    Create Listing
                  </span>

                  {isSubmitting && (
                    <span className="absolute inset-0 flex items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    </span>
                  )}
                </button>
                {submitError && (
                  <>
                    <br />
                    <p className="mt-2 text-red-500 text-sm">{submitError}</p>
                  </>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateListingPage;
