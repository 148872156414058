// Helper function (can be placed in a utility file)
export const formatAvailableDate = (date: unknown): string => {
  if (!date) return 'N/A';

  try {
    if (typeof date === 'object' && 'seconds' in date) {
      return new Date(
        (date.seconds as unknown as number) * 1000
      ).toLocaleDateString('en-IE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }

    if (typeof date === 'string') {
      return new Date(date).toLocaleDateString('en-IE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }

    return 'N/A';
  } catch {
    return 'N/A';
  }
};
