import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import PropertyPictures from '../../components/property/property-pictures/PropertyPictures';
import PropertyDetails from '../../components/property/property-details/PropertyDetails';
import PropertyDescription from '../../components/property/property-description/PropertyDescription';
import Amenities from '../../components/amenities/Amenities';
import BERDetails from '../../components/BER-details/BERDetails';
import PropertyStatistics from '../../components/property/property-statistics/PropertyStatistics';
import LandlordInformation from '../../components/landlord-information/LandlordInformation';
import ContactLandlord from '../../components/contact-landlord/ContactLandlord';
import ShareModal from '../../components/property/property-card/ShareModal';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import { useAuth } from '../../auth/firebaseAuth';
import {
  getDocumentByField,
  insertDocument,
  deleteDocument,
  queryDocuments,
  getDocumentById,
  AutoApplyUser,
  updateDocument
} from '../../utility/firebaseHelpers';
import { Timestamp, where, serverTimestamp } from 'firebase/firestore';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import PropertyApplicationFAQ from '../../components/property-inquiry-faq/propertyInquiryFaq';
import MortgageCalculator from '../../components/property-tools/MortgageCalculator/MortgageCalculator';
import PropertyAlerts from '../../components/property-tools/PropertyAlerts/PropertyAlerts';
import RandomInsight from '../../components/random-insight/RandomInsight';
import ExplorePropertyCategories from '../../components/explore-property-categories/ExplorePropertyCategories';
import { usePropertyViews } from '../../hooks/usePropertyViews';
import { ShareToApplyButton } from '../../components/share-to-apply/ShareToApplyButton';
import { SupportNetwork } from '../../components/support-network/SupportNetwork';
import FeedbackForm from '../../components/feedback-form/FeedbackForm';
import FeedbackCard from '../../components/feedback-card/FeedbackCard';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';
import { isElementInViewport } from '../../utility/viewportUtils';
import SimilarListings from '../../components/similar-listings/SimilarListings';
import PropertyVideo from '../../components/property-video/PropertyVideo';
import VirtualTour from '../../components/virtual-tour/VirtualTour';
import SocialProof from '../../components/social-proof/SocialProof';
import toast from 'react-hot-toast';
import AutoApplyCTA from '../../components/auto-apply-cta/AutoApplyCTA';
import PrintButton from '../../components/print-button/PrintButton';
import { formatAvailableDate } from '../../utility/date-formatter';

const PropertyViewPage: React.FC = () => {
  const { listingId } = useParams<{
    listingId: string;
  }>();
  const { currentUser } = useAuth();
  const [property, setProperty] = useState<Listing | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentShareUrl, setCurrentShareUrl] = useState('');
  const [currentShareTitle, setCurrentShareTitle] = useState('');
  const [isReporting, setIsReporting] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const analytics = useAnalyticsWithConsent();
  const viewStartTime = useRef(new Date());
  const interactionCount = useRef(0);
  const maxScrollDepth = useRef(0);
  const sectionViewTimes = useRef<Record<string, number>>({});
  const lastSectionChange = useRef(new Date());

  analytics.trackPixelPageView();

  usePropertyViews(listingId);

  useEffect(() => {
    if (property) {
      // Track initial property view
      analytics.trackPropertyView(property.id, {
        type: property.type,
        price: property.price,
        location: property.location,
        images: property.images,
        amenities: property.amenities,
        virtualTourUrl: property.virtualTourUrl
      });
    }
  }, [analytics, property]);

  // Track scroll and section views
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const currentDepth = Math.round(
        ((scrollTop + windowHeight) / documentHeight) * 100
      );

      maxScrollDepth.current = Math.max(maxScrollDepth.current, currentDepth);

      // Update section view times
      const sections = [
        'photos',
        'details',
        'description',
        'amenities',
        'contact'
      ];
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element && isElementInViewport(element)) {
          const now = new Date();
          sectionViewTimes.current[section] =
            (sectionViewTimes.current[section] || 0) +
            (now.getTime() - lastSectionChange.current.getTime());
          lastSectionChange.current = now;
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Track interactions
  const trackInteraction = (type: string) => {
    interactionCount.current += 1;
    analytics.trackInteraction(type, {
      property_id: property?.id,
      scroll_depth: maxScrollDepth.current,
      time_since_view: new Date().getTime() - viewStartTime.current.getTime()
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPropertyDetails(listingId ?? '')
      .then((propertyData) => {
        const checkBookmarkStatus = async (id: string) => {
          if (currentUser) {
            const bookmarks = await queryDocuments('bookmarks', [
              where('userId', '==', currentUser.uid),
              where('listingId', '==', id)
            ]);
            setIsBookmarked(bookmarks.length > 0);
          } else {
            const bookmarks = JSON.parse(
              localStorage.getItem('bookmarks') || '[]'
            );
            setIsBookmarked(bookmarks.includes(id));
          }
        };

        setProperty(propertyData);
        if (listingId) {
          checkBookmarkStatus(listingId);
        }
      })
      .catch((error) =>
        console.error('Error fetching property details:', error)
      )
      .finally(() => setIsLoading(false));
  }, [currentUser, listingId]);

  useEffect(() => {
    return () => {
      if (property) {
        // Track overall engagement
        analytics.trackPropertyEngagement(property.id, {
          viewDuration: new Date().getTime() - viewStartTime.current.getTime(),
          scrollDepth: maxScrollDepth.current,
          interactionCount: interactionCount.current,
          inquirySent: false, // Update based on actual inquiry status
          sectionViewTimes: sectionViewTimes.current
        });

        // Track content effectiveness
        analytics.trackContentEffectiveness(property.id, {
          section_view_times: sectionViewTimes.current,
          most_viewed_section: Object.entries(sectionViewTimes.current).sort(
            ([, a], [, b]) => b - a
          )[0]?.[0],
          images_viewed: property.images.length,
          amenities_viewed: property.amenities.length
        });

        // Track performance
        const navTiming = performance.getEntriesByType(
          'navigation'
        )[0] as PerformanceNavigationTiming;
        analytics.trackPerformance(`property_${property.id}`, {
          loadTime: navTiming.loadEventEnd,
          renderTime: navTiming.domComplete - navTiming.domInteractive,
          resourcesLoaded: performance.getEntriesByType('resource').length,
          timeToInteractive: navTiming.domInteractive
        });
      }
    };
  }, [property]);

  const fetchPropertyDetails = async (id: string): Promise<Listing | null> => {
    return await getDocumentByField<Listing>('listings', 'id', Number(id));
  };

  const handleBookmarkToggle = async () => {
    trackInteraction('bookmark');
    if (currentUser) {
      try {
        const bookmarks = await queryDocuments('bookmarks', [
          where('userId', '==', currentUser.uid),
          where('listingId', '==', listingId)
        ]);
        if (bookmarks.length === 0) {
          await insertDocument('bookmarks', {
            userId: currentUser.uid,
            listingId,
            createdAt: serverTimestamp()
          });
          setIsBookmarked(true);
        } else {
          await deleteDocument('bookmarks', bookmarks[0].id);
          setIsBookmarked(false);
        }
      } catch (error) {
        console.error('Error toggling bookmark:', error);
      }
    } else {
      const bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
      const updatedBookmarks = isBookmarked
        ? bookmarks.filter((bookmarkId: string) => bookmarkId !== listingId)
        : [...bookmarks, listingId];
      localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
      setIsBookmarked(!isBookmarked);
    }
  };

  const handleShare = async (listing: Listing) => {
    trackInteraction('share');
    const shareUrl = `${window.location.origin}/property/${listing.id}`;
    const shareTitle = `Check out this property: ${listing.title}`;
    setCurrentShareUrl(shareUrl);
    setCurrentShareTitle(shareTitle);
    setIsShareModalOpen(true);

    // Track share for Auto Apply feature if user is logged in and remote config: isAutoApplyEnabled is enabled
    if (currentUser && remoteConfig.isAutoApplyEnabled()) {
      try {
        // Get existing auto apply user document
        const autoApplyUser = await getDocumentById<AutoApplyUser>(
          'autoApplyUsers',
          currentUser.uid
        );

        if (autoApplyUser) {
          // Check if property already shared
          const alreadyShared = autoApplyUser.sharedProperties?.some(
            (share) => share.propertyId === listing.id.toString()
          );

          if (!alreadyShared) {
            const updatedShareCount = (autoApplyUser.shareCount || 0) + 1;
            if (updatedShareCount === 1) {
              toast.success(
                'Share one more property to activate auto-apply feature!'
              );
            }
            const updatedProperties = [
              ...(autoApplyUser.sharedProperties || []),
              {
                propertyId: listing.id.toString(),
                sharedAt: Timestamp.now(),
                sharedWith: 'social_share'
              }
            ];

            // Update document
            await updateDocument('autoApplyUsers', currentUser.uid, {
              shareCount: updatedShareCount,
              sharedProperties: updatedProperties,
              lastUpdated: Timestamp.now()
            });

            // Check if user has reached share threshold
            if (updatedShareCount >= 2 && !autoApplyUser.isEnabled) {
              await updateDocument('autoApplyUsers', currentUser.uid, {
                isEnabled: true,
                activatedAt: Timestamp.now(),
                expiresAt: Timestamp.fromDate(
                  new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                )
              });

              toast.success(
                'Auto Apply feature activated! Go to auto-apply dashboard to set your preferences.'
              );
            }
          }
        } else {
          // Create new auto_apply_users document
          await insertDocument(
            'autoApplyUsers',
            {
              userId: currentUser.uid,
              isEnabled: false,
              shareCount: 1,
              sharedProperties: [
                {
                  propertyId: listing.id.toString(),
                  sharedAt: Timestamp.now(),
                  sharedWith: 'social_share'
                }
              ],
              createdAt: Timestamp.now(),
              lastUpdated: Timestamp.now(),
              stats: {
                totalApplications: 0,
                successfulApplications: 0
              }
            },
            currentUser.uid
          );
        }
      } catch (error) {
        console.error('Error tracking share for Auto Apply:', error);
      }
    }
  };

  const handleReportListing = async () => {
    if (!reportReason.trim()) {
      setFeedbackMessage('Please provide a reason for reporting.');
      return;
    }

    const reportData = {
      listingId,
      reporterId: currentUser ? currentUser.uid : 'guest',
      reportReason,
      timestamp: serverTimestamp(),
      propertyTitle: property?.title
    };

    try {
      await insertDocument('listingReports', reportData);
      setFeedbackMessage(
        'Thank you for reporting. We will review this listing.'
      );
      setReportReason('');

      setTimeout(() => {
        setIsReporting(false);
        setFeedbackMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Error reporting listing:', error);
      setFeedbackMessage(
        'An error occurred while submitting your report. Please try again.'
      );
    }
  };

  if (isLoading) return <LoadingSpinner />;
  if (!property)
    return <div className="text-center py-12">Property not found</div>;

  return (
    <div className="py-12">
      <div className="container mx-auto px-4">
        {/* Property Pictures and Basic Info */}
        <div id="photos">
          <PropertyPictures
            images={property.images}
            title={property.title}
            isBookmarked={isBookmarked}
            handleBookmark={handleBookmarkToggle}
            handleShare={() => handleShare(property)}
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          {/* Main Content Area */}
          <div className="lg:col-span-2 space-y-8">
            <div id="details">
              <PropertyDetails
                location={property.location}
                type={property.type}
                listingIntent={property.listingIntent}
                price={property.price}
                bedrooms={property.bedrooms}
                bathrooms={property.bathrooms}
                size={typeof property.size === 'number' ? property.size : 0}
                availableFrom={formatAvailableDate(property.availableFrom)}
                dateAdded={formatAvailableDate(property.dateAdded)}
              />
            </div>
            <PrintButton property={property} />
            <div id="description">
              <PropertyDescription description={property.description} />
            </div>
            <div id="amenities">
              <Amenities amenities={property.amenities} />
            </div>
            {property.berRating && <BERDetails berRating={property.berRating} />}
            <PropertyStatistics propertyViews={property.propertyViews} />
            {property.videoUrl && (
              <PropertyVideo videoUrl={property.videoUrl} />
            )}
            {property.virtualTourUrl && (
              <VirtualTour tourUrl={property.virtualTourUrl} />
            )}

            <ShareToApplyButton listing={property} />
            {/* <RandomInsight /> */}
          </div>

          {/* Sidebar Area */}
          <div className="space-y-8">
            <div id="contact">
              {!property.isExternalListing && (
                <LandlordInformation user={property.user} />
              )}
              <ContactLandlord
                externalListingUrl={property.externalListingUrl}
                isExternalListing={property.isExternalListing}
                propertyTitle={property.title}
                listingId={listingId ?? ''}
                landlordId={property?.propertyOwnerUid ?? ''}
              />
            </div>
            <PropertyApplicationFAQ />
            {property.listingIntent === 'rent' ? (
              <PropertyAlerts variant="compact" />
            ) : (
              <MortgageCalculator />
            )}

            {/* Report Listing Section */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
              {isReporting ? (
                feedbackMessage ? (
                  <div className="flex flex-col items-center">
                    <CheckCircleIcon className="w-12 h-12 text-green-500 mb-3" />
                    <p className="text-center text-sm text-gray-700">
                      {feedbackMessage}
                    </p>
                  </div>
                ) : (
                  <>
                    <textarea
                      placeholder="Please specify the reason for reporting this listing"
                      value={reportReason}
                      onChange={(e) => setReportReason(e.target.value)}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none transition duration-300 mb-4"
                    ></textarea>
                    <button
                      onClick={handleReportListing}
                      className="w-full bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition-all duration-300"
                    >
                      Submit Report
                    </button>
                    <button
                      onClick={() => {
                        setIsReporting(false);
                        setFeedbackMessage(null);
                      }}
                      className="w-full bg-gray-300 text-gray-700 px-6 py-3 rounded-lg mt-2 hover:bg-gray-400 transition-all duration-300"
                    >
                      Cancel
                    </button>
                  </>
                )
              ) : (
                <button
                  onClick={() => {
                    trackInteraction('report_button_clicked');
                    setIsReporting(true);
                  }}
                  className="w-full bg-gray-100 text-gray-600 px-6 py-3 rounded-lg hover:bg-gray-200 transition-all duration-300"
                >
                  Report this Listing
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {remoteConfig.isAutoApplyEnabled() && <AutoApplyCTA className="mb-8" />}
      {/* Explore Categories and Share Modal */}
      <SimilarListings currentListing={property} />
      <SupportNetwork />
      <SocialProof userType="renter" />
      <ExplorePropertyCategories />
      {remoteConfig.isFeedbackEnabled() && (
        <>
          <FeedbackCard
            variant="compact"
            title="Quick Feedback"
            subtitle="Tell us what you think"
          />
          <FeedbackForm />
        </>
      )}

      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={currentShareUrl}
        title={currentShareTitle}
      />
    </div>
  );
};

export default PropertyViewPage;
