import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import SearchBar from '../../components/search-bar/SearchBar';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import SortDropdown from '../../components/sort-dropdown/SortDropdown';
import Header from '../../components/header/Header';
import PropertyCard from '../../components/property/property-card/PropertyCard';
import {
  Listing,
  ListingIntent,
  PropertyType
} from '../../components/property/property-card/PropertyCardTypes';
import {
  AutoApplyUser,
  deleteDocument,
  fetchListings,
  getDocumentById,
  insertDocument,
  queryDocuments,
  updateDocument
} from '../../utility/firebaseHelpers';
import ShareModal from '../../components/property/property-card/ShareModal';
import { useAuth } from '../../auth/firebaseAuth';
import { serverTimestamp, Timestamp, where } from 'firebase/firestore';
import RandomInsight from '../../components/random-insight/RandomInsight';
import PropertyListingCTA from '../../components/property-listing-cta/PropertyListingCTA';
import PropertyAlertsCTA from '../../components/property-alerts-cta/PropertyAlertsCTA';
import ExplorePropertyCategories from '../../components/explore-property-categories/ExplorePropertyCategories';
import { SupportNetwork } from '../../components/support-network/SupportNetwork';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';
import FeedbackCard from '../../components/feedback-card/FeedbackCard';
import FeedbackForm from '../../components/feedback-form/FeedbackForm';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';
import { AnalyticsEvents } from '../../analytics/firebaseAnalytics';
import SocialProof from '../../components/social-proof/SocialProof';
import toast from 'react-hot-toast';
import AutoApplyCTA from '../../components/auto-apply-cta/AutoApplyCTA';
import ReactPixel from 'react-facebook-pixel';

export interface SearchParams {
  location: string;
  minPrice: number;
  maxPrice: number;
  bedrooms: number;
  bathrooms: number;
  propertyType: PropertyType | 'Any';
  berRating: string;
  availableFrom: Date | null;
  listingIntent: ListingIntent | 'Any';
}

interface BrowseListingsPageProps {
  initialSearchParams?: Partial<SearchParams>;
}

const BrowseListingsPage: React.FC<BrowseListingsPageProps> = ({
  initialSearchParams
}) => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [listings, setListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalListings, setTotalListings] = useState(0);
  const [sortOption, setSortOption] = useState('bestMatch');
  const [bookmarkedListings, setBookmarkedListings] = useState<number[]>([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentShareUrl, setCurrentShareUrl] = useState('');
  const [currentShareTitle, setCurrentShareTitle] = useState('');

  const analytics = useAnalyticsWithConsent();
  const sessionStart = useRef(new Date());
  const searchStartTime = useRef<Date>();
  const interactionCount = useRef(0);
  const viewedListings = useRef<Set<number>>(new Set());
  const searchHistory = useRef<SearchParams[]>([]);

  
  analytics.trackPixelPageView();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    location: '',
    minPrice: 0,
    maxPrice: 0,
    bedrooms: 0,
    bathrooms: 0,
    propertyType: 'Any',
    berRating: 'Any',
    availableFrom: null,
    listingIntent: 'Any',
    ...initialSearchParams // Merge initial search params if provided
  });

  const listingsPerPage = 15;

  const parseQueryParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    return {
      location: params.get('location') || '',
      minPrice: parseInt(params.get('minPrice') || '0'),
      maxPrice: parseInt(params.get('maxPrice') || '0'),
      bedrooms: parseInt(params.get('bedrooms') || '0'),
      bathrooms: parseInt(params.get('bathrooms') || '0'),
      propertyType: (params.get('propertyType') as PropertyType) || 'Any',
      berRating: params.get('berRating') || 'Any',
      availableFrom: params.get('availableFrom')
        ? new Date(params.get('availableFrom')!)
        : null,
      listingIntent: (params.get('listingIntent') as ListingIntent) || 'Any'
    };
  }, [location.search]);

  const updateQueryParams = useCallback(
    (params: SearchParams) => {
      const searchParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== '' && value !== 'Any' && value !== 0) {
          if (key === 'availableFrom' && value instanceof Date) {
            searchParams.append(key, value.toISOString());
          } else {
            searchParams.append(key, value.toString());
          }
        }
      });
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true
      });
    },
    [navigate, location.pathname]
  );

  const loadListings = useCallback(
    async (page: number, params: SearchParams, sort: string) => {
      setIsLoading(true);
      try {
        const { listings, total } = await fetchListings(
          page,
          listingsPerPage,
          params,
          sort
        );
        setListings(listings);
        setTotalListings(total);
      } catch (error) {
        console.error('Failed to load listings:', error);
        toast.error('Failed to load listings. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [listingsPerPage]
  );

  useEffect(() => {
    const urlParams = parseQueryParams();
    const mergedParams = {
      ...searchParams,
      ...urlParams,
      ...initialSearchParams
    };
    setSearchParams(mergedParams);
    setCurrentPage(1); // Reset to first page on search params change
    loadListings(1, mergedParams, sortOption);
  }, [
    location.search,
    sortOption,
    loadListings,
    parseQueryParams,
    initialSearchParams
  ]);

  useEffect(() => {
    const fetchBookmarks = async () => {
      if (currentUser) {
        try {
          const bookmarks = await queryDocuments('bookmarks', [
            where('userId', '==', currentUser.uid)
          ]);
          const bookmarkedIds = bookmarks.map((bookmark) =>
            Number(bookmark.listingId)
          );
          setBookmarkedListings(bookmarkedIds);
        } catch (error) {
          console.error('Error fetching bookmarks:', error);
        }
      } else {
        const storedBookmarks = localStorage.getItem('bookmarkedListings');
        if (storedBookmarks) {
          setBookmarkedListings(JSON.parse(storedBookmarks));
        }
      }
    };

    if (!loading) {
      fetchBookmarks();
    }
  }, [currentUser, loading]);

  // Track search results
  useEffect(() => {
    if (!isLoading && searchStartTime.current) {
      const searchDuration =
        new Date().getTime() - searchStartTime.current.getTime();

      analytics.logEvent(AnalyticsEvents.SEARCH_RESULTS, {
        results_count: totalListings,
        search_duration: searchDuration,
        zero_results: totalListings === 0,
        has_filters: hasActiveFilters(searchParams),
        filters_used: getActiveFilters(searchParams),
        sort_option: sortOption
      });
    }
  }, [listings, isLoading, totalListings]);

  const handleBookmarkToggle = async (id: number) => {
    analytics.logEvent(AnalyticsEvents.BOOKMARK_INTERACTION, {
      property_id: id,
      action: bookmarkedListings.includes(id) ? 'remove' : 'add',
      source: AnalyticsEvents.SEARCH_RESULTS,
      page_number: currentPage,
      position_in_results: listings.findIndex((l) => l.id === id) + 1,
      authenticated: !!currentUser
    });

    if (currentUser) {
      try {
        const bookmarks = await queryDocuments('bookmarks', [
          where('userId', '==', currentUser.uid),
          where('listingId', '==', id.toString())
        ]);

        if (bookmarks.length === 0) {
          await insertDocument('bookmarks', {
            userId: currentUser.uid,
            listingId: id.toString(),
            createdAt: serverTimestamp()
          });
          setBookmarkedListings((prev) => [...prev, id]);
        } else {
          await deleteDocument('bookmarks', bookmarks[0].id);
          setBookmarkedListings((prev) =>
            prev.filter((listingId) => listingId !== id)
          );
        }
      } catch (error) {
        console.error('Error toggling bookmark:', error);
      }
    } else {
      setBookmarkedListings((prevBookmarkedListings) => {
        let newBookmarkedListings;
        if (prevBookmarkedListings.includes(id)) {
          newBookmarkedListings = prevBookmarkedListings.filter(
            (listingId) => listingId !== id
          );
        } else {
          newBookmarkedListings = [...prevBookmarkedListings, id];
        }
        localStorage.setItem(
          'bookmarkedListings',
          JSON.stringify(newBookmarkedListings)
        );
        return newBookmarkedListings;
      });
    }
  };

  const totalPages = Math.ceil(totalListings / listingsPerPage);

  const handlePageChange = (newPage: number) => {
    if (newPage === currentPage || isLoading) return;
    
    analytics.logEvent(AnalyticsEvents.PAGINATION_INTERACTION, {
      previous_page: currentPage,
      new_page: newPage,
      results_per_page: listingsPerPage,
      total_pages: totalPages,
      sort_option: sortOption,
      search_params: searchParams
    });

    setCurrentPage(newPage);
    loadListings(newPage, searchParams, sortOption);
    
    // Scroll to top smoothly
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleSearch = (params: SearchParams) => {
    searchStartTime.current = new Date();
    searchHistory.current.push(params);

    analytics.logEvent('property_search', {
      search_params: {
        location: params.location,
        price_range:
          params.maxPrice > 0 ? `${params.minPrice}-${params.maxPrice}` : 'any',
        bedrooms: params.bedrooms || 'any',
        bathrooms: params.bathrooms || 'any',
        property_type: params.propertyType,
        listing_intent: params.listingIntent,
        ber_rating: params.berRating,
        available_from: params.availableFrom?.toISOString()
      },
      search_refinement: searchHistory.current.length > 1,
      previous_search_count: searchHistory.current.length - 1,
      session_duration: new Date().getTime() - sessionStart.current.getTime()
    });

    setSearchParams(params);
    setCurrentPage(1);
    updateQueryParams(params);
    loadListings(1, params, sortOption);
  };

  // Helper functions
  const hasActiveFilters = (params: SearchParams): boolean => {
    return Object.entries(params).some(([key, value]) => {
      if (key === 'location') return !!value;
      if (key === 'availableFrom') return !!value;
      if (typeof value === 'number') return value > 0;
      return value !== 'Any';
    });
  };

  const getActiveFilters = (params: SearchParams): string[] => {
    return Object.entries(params)
      .filter(([key, value]) => {
        if (key === 'location') return !!value;
        if (key === 'availableFrom') return !!value;
        if (typeof value === 'number') return value > 0;
        return value !== 'Any';
      })
      .map(([key]) => key);
  };

  const getPriceRange = (price: number): string => {
    if (price < 1000) return 'low';
    if (price < 2000) return 'medium';
    return 'high';
  };

  // Track listing views in the search results
  const handleListingView = useCallback(
    (listing: Listing) => {
      if (!viewedListings.current.has(listing.id)) {
        viewedListings.current.add(listing.id);

        analytics.trackPropertyView(listing.id, {
          type: listing.type,
          price: listing.price,
          location: listing.location,
          position_in_results:
            listings.findIndex((l) => l.id === listing.id) + 1,
          page_number: currentPage,
          source: 'search_results',
          search_params: searchParams
        });
      }
    },
    [currentPage, listings, searchParams]
  );

  const handleSort = (option: string) => {
    analytics.logEvent(AnalyticsEvents.SEARCH_SORT, {
      sort_option: option,
      previous_sort: sortOption,
      results_count: totalListings,
      page_number: currentPage
    });

    setSortOption(option);
    setCurrentPage(1);
    loadListings(1, searchParams, option);
  };

  const handleShare = async (listing: Listing) => {
    analytics.logEvent(AnalyticsEvents.SHARE_INTERACTION, {
      property_id: listing.id,
      property_type: listing.type,
      price_range: getPriceRange(listing.price),
      source: 'search_results',
      page_number: currentPage,
      position_in_results: listings.findIndex((l) => l.id === listing.id) + 1
    });

    const shareUrl = `${window.location.origin}/property/${listing.id}`;
    const shareTitle = `Check out this property on RentSmart.ie: ${listing.title}`;
    setCurrentShareUrl(shareUrl);
    setCurrentShareTitle(shareTitle);
    setIsShareModalOpen(true);

    // Track share for Auto Apply feature if user is logged in and remote config: isAutoApplyEnabled is enabled
    if (currentUser && remoteConfig.isAutoApplyEnabled()) {
      try {
        // Get existing auto apply user document
        const autoApplyUser = await getDocumentById<AutoApplyUser>(
          'autoApplyUsers',
          currentUser.uid
        );

        if (autoApplyUser) {
          // Check if property already shared
          const alreadyShared = autoApplyUser.sharedProperties?.some(
            (share) => share.propertyId === listing.id.toString()
          );

          if (!alreadyShared) {
            const updatedShareCount = (autoApplyUser.shareCount || 0) + 1;
            if (updatedShareCount === 1) {
              toast.success(
                'Share one more property to activate auto-apply feature!'
              );
            }
            const updatedProperties = [
              ...(autoApplyUser.sharedProperties || []),
              {
                propertyId: listing.id.toString(),
                sharedAt: Timestamp.now(),
                sharedWith: 'social_share'
              }
            ];

            // Update document
            await updateDocument('autoApplyUsers', currentUser.uid, {
              shareCount: updatedShareCount,
              sharedProperties: updatedProperties,
              lastUpdated: Timestamp.now()
            });

            // Check if user has reached share threshold
            if (updatedShareCount >= 2 && !autoApplyUser.isEnabled) {
              await updateDocument('autoApplyUsers', currentUser.uid, {
                isEnabled: true,
                activatedAt: Timestamp.now(),
                expiresAt: Timestamp.fromDate(
                  new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                )
              });

              toast.success(
                'Auto Apply feature activated! Go to auto-apply dashboard to set your preferences.'
              );
            }
          }
        } else {
          // Create new auto_apply_users document
          await insertDocument(
            'autoApplyUsers',
            {
              userId: currentUser.uid,
              isEnabled: false,
              shareCount: 1,
              sharedProperties: [
                {
                  propertyId: listing.id.toString(),
                  sharedAt: Timestamp.now(),
                  sharedWith: 'social_share'
                }
              ],
              createdAt: Timestamp.now(),
              lastUpdated: Timestamp.now(),
              stats: {
                totalApplications: 0,
                successfulApplications: 0
              }
            },
            currentUser.uid
          );
        }
      } catch (error) {
        console.error('Error tracking share for Auto Apply:', error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentPage]);

  // Track session metrics on unmount
  useEffect(() => {
    return () => {
      analytics.logEvent(AnalyticsEvents.SEARCH_SESSION_END, {
        session_duration: new Date().getTime() - sessionStart.current.getTime(),
        total_searches: searchHistory.current.length,
        listings_viewed: viewedListings.current.size,
        pages_viewed: currentPage,
        interactions_count: interactionCount.current,
        conversion_actions: {
          bookmarks: bookmarkedListings.length,
          shares: 0, // Update with actual share count if tracked
          inquiries: 0 // Update with actual inquiry count if tracked
        }
      });
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  analytics.trackPixelPageView();

  const renderPaginationButtons = () => {
    const range = 2; // Show 2 pages before and after current page
    const pages = [];
    
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - range && i <= currentPage + range)
      ) {
        pages.push(i);
      } else if (pages[pages.length - 1] !== '...') {
        pages.push('...');
      }
    }

    return pages.map((page, index) => {
      if (page === '...') {
        return (
          <span key={`ellipsis-${index}`} className="px-4 py-2">
            ...
          </span>
        );
      }

      return (
        <button
          key={page}
          onClick={() => handlePageChange(Number(page))}
          className={`relative inline-flex items-center px-4 py-2 border ${
            page === currentPage
              ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
              : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
          } text-sm font-medium`}
          aria-current={page === currentPage ? 'page' : undefined}
        >
          {page}
        </button>
      );
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Header />
      <SearchBar
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onSearch={handleSearch}
      />

      <div className="flex justify-between items-center my-6">
        <p className="text-gray-600">
          {totalListings} {listings.length > 1 ? 'Properties' : 'Property'} to
          {searchParams.listingIntent === 'Any'
            ? ' Browse'
            : searchParams.listingIntent === 'rent'
              ? ' Rent'
              : searchParams.listingIntent === 'sale'
                ? ' Buy'
                : ' Share'}{' '}
          in {searchParams.location.length ? searchParams.location : 'Ireland'}
        </p>
        <SortDropdown
          options={[
            { label: 'Best Match', value: 'bestMatch' },
            { label: 'Price: Low to High', value: 'priceLowToHigh' },
            { label: 'Price: High to Low', value: 'priceHighToLow' }
          ]}
          selectedOption={sortOption}
          onSelect={handleSort}
        />
      </div>
      <p className="text-lg md:text-xl font-medium">Featured properties</p>
      {listings.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {listings.map((listing) => (
              <div
                key={listing.id}
                onMouseEnter={() => handleListingView(listing)}
              >
                <PropertyCard
                  listing={listing}
                  isBookmarked={bookmarkedListings.includes(listing.id)}
                  onBookmarkToggle={handleBookmarkToggle}
                  onShare={() => handleShare(listing)}
                />
              </div>
            ))}
          </div>
          <div className="mt-8 flex justify-center">
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || isLoading}
                className={`relative inline-flex items-center px-2 py-2 rounded-l-md border ${
                  currentPage === 1 || isLoading
                    ? 'border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed'
                    : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                } text-sm font-medium`}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              
              {renderPaginationButtons()}
              
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || isLoading}
                className={`relative inline-flex items-center px-2 py-2 rounded-r-md border ${
                  currentPage === totalPages || isLoading
                    ? 'border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed'
                    : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
                } text-sm font-medium`}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
          <PropertyAlertsCTA />
          {remoteConfig.isAutoApplyEnabled() && (
            <AutoApplyCTA className="mb-8" />
          )}
          <SupportNetwork />
          {/* <RandomInsight /> */}
          <ExplorePropertyCategories />
          <SocialProof userType="renter" />
          <PropertyListingCTA />

          {remoteConfig.isFeedbackEnabled() && (
            <>
              <FeedbackCard
                variant="compact"
                title="Quick Feedback"
                subtitle="Tell us what you think"
              />
              <FeedbackForm />
            </>
          )}
        </>
      ) : (
        <div className="text-center text-gray-600 mt-10">
          No properties found matching your search criteria.
        </div>
      )}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={currentShareUrl}
        title={currentShareTitle}
      />
    </div>
  );
};

export default BrowseListingsPage;
