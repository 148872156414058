import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth/firebaseAuth';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../../auth/firebaseConfig';
import confetti from 'canvas-confetti';
import { ArrowRight, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ResponseTimeBadge from '../response-time-badge/ResponseTimeBadge';

export interface InquiryMessage {
  senderId: string;
  recipientId: string;
  content: string;
  timestamp: any;
  senderName: string;
  senderEmail: string;
  isGuestInquiry: boolean;
  listingId: string;
  propertyTitle: string;
  archived: boolean;
}

interface ContactLandlordProps {
  externalListingUrl: string | undefined;
  isExternalListing: boolean;
  listingId: string;
  landlordId: string;
  propertyTitle: string;
}

const ContactLandlord: React.FC<ContactLandlordProps> = ({
  externalListingUrl,
  isExternalListing,
  listingId,
  landlordId,
  propertyTitle
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [quickAccountName, setQuickAccountName] = useState('');
  const [quickAccountEmail, setQuickAccountEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [animate, setAnimate] = useState(false);
  const [showQuickSignup, setShowQuickSignup] = useState(false);

  const messageTemplates = [
    "I'm interested in the property '{propertyTitle}' (Listing ID: {listingId}) and would like to schedule a viewing. Could you please provide some available dates and times?",
    "I have a few questions about the property '{propertyTitle}' (Listing ID: {listingId}). Is it possible to arrange a quick call to discuss?",
    "Greetings! I'm wondering if there's any flexibility with the move-in date for the property '{propertyTitle}' (Listing ID: {listingId})?",
    "Could you please provide more information about the utilities and any additional costs associated with renting '{propertyTitle}' (Listing ID: {listingId})?",
    "I'm interested in a long-term lease for '{propertyTitle}' (Listing ID: {listingId}). Are there any discounts available for signing a lease longer than 12 months?"
  ];

  useEffect(() => {
    if (currentUser) {
      setName(currentUser.displayName || '');
      setEmail(currentUser.email || '');
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedTemplate) {
      let personalizedMessage = selectedTemplate
        .replace('{listingId}', listingId)
        .replace('{propertyTitle}', propertyTitle);
      if (name) {
        personalizedMessage = `Hi, my name is ${name}. ${personalizedMessage}`;
      }
      setMessage(personalizedMessage);
    }
  }, [selectedTemplate, name, listingId, propertyTitle]);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTemplate(e.target.value);
  };

  const triggerSuccessAnimation = () => {
    // Trigger entrance animation
    setAnimate(true);

    // Trigger confetti animation
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    // Second confetti burst after a short delay
    setTimeout(() => {
      confetti({
        particleCount: 50,
        spread: 50,
        origin: { y: 0.6 },
        angle: 60
      });
      confetti({
        particleCount: 50,
        spread: 50,
        origin: { y: 0.6 },
        angle: 120
      });
    }, 250);
  };

  const handleQuickSignup = () => {
    // Store inquiry data in localStorage for pre-filling signup form
    const inquiryData = {
      name: quickAccountName,
      email: quickAccountEmail,
      fromInquiry: true,
      timestamp: new Date().toISOString()
    };
    console.log('inquiryData: ', inquiryData);
    localStorage.setItem('quickSignupData', JSON.stringify(inquiryData));
    navigate('/signup');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!landlordId || !listingId) {
      console.error('Landlord ID or Listing ID is missing');
      return;
    }

    setIsSending(true);

    const finalMessage =
      message.includes(listingId) && message.includes(propertyTitle)
        ? message
        : `${message}\n\nReferring to property: ${propertyTitle} (Listing ID: ${listingId})`;

    const inquiryMessage: InquiryMessage = {
      senderId: currentUser ? currentUser.uid : 'guest',
      recipientId: landlordId,
      content: finalMessage,
      timestamp: serverTimestamp(),
      senderName: name,
      senderEmail: email,
      archived: false,
      isGuestInquiry: !currentUser,
      listingId: listingId,
      propertyTitle: propertyTitle
    };

    try {
      if (currentUser) {
        const conversationsRef = collection(db, 'conversations');
        const q = query(
          conversationsRef,
          where('participants', 'array-contains', currentUser.uid),
          where('listingId', '==', listingId)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          await addDoc(conversationsRef, {
            participants: [currentUser.uid, landlordId],
            listingId: listingId,
            propertyTitle: propertyTitle,
            lastMessage: finalMessage,
            lastMessageTimestamp: serverTimestamp()
          });
        } else {
          const conversationDoc = querySnapshot.docs[0];
          await addDoc(
            collection(db, 'conversations', conversationDoc.id, 'messages'),
            inquiryMessage
          );
        }
      } else {
        await addDoc(collection(db, 'guestInquiries'), inquiryMessage);
        setShowQuickSignup(true);
      }

      setMessage('');
      setSelectedTemplate('');
      setQuickAccountName(name);
      setQuickAccountEmail(email);
      if (!currentUser) {
        setName('');
        setEmail('');
      }
      setSendSuccess(true);
      triggerSuccessAnimation();
    } catch (error) {
      console.error('Error sending inquiry:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 mt-4">
      {/* Header */}
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">
          Contact listing owner
        </h2>
      </div>

      {/* Content */}
      <div className="p-6">
        {isExternalListing ? (
            <div className="space-y-4">
            <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
              <p className="text-yellow-800">
              This is an external listing. You will be redirected to the original listing site to contact the owner.
              </p>
            </div>
            <a
              href={externalListingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-300 font-semibold flex items-center justify-center"
            >
              View on External Site
              <ArrowRight className="w-5 h-5 ml-2" />
            </a>
            </div>
        ) : sendSuccess ? (
          <div
            className={`text-center transform transition-all duration-500 ${
              animate ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
            }`}
          >
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h3 className="text-2xl font-bold text-gray-800 mb-4">
              Message Sent Successfully!
            </h3>
            <ResponseTimeBadge />

            {!currentUser && showQuickSignup && (
              <div className="mt-6 bg-blue-50 rounded-lg pt-sm-2 pb-4 flex flex-col items-center">
                <h4 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Create an account quickly to:
                </h4>
                <ul className="space-y-4 mb-6">
                  <li className="flex items-center text-gray-700">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-500 leading-none" />
                    Track your inquiry and responses
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-500 leading-none" />
                    Save your favorite properties
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-500 leading-none" />
                    Get notified about similar properties
                  </li>
                </ul>
                <button
                  onClick={handleQuickSignup}
                  className="bg-blue-600 text-white px-10 py-3 rounded-lg hover:bg-blue-700 transition-all duration-300 flex items-center justify-center font-semibold"
                >
                  Quick Sign Up
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              </div>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className={`text-center`}>
              <ResponseTimeBadge />
            </div>
            {!currentUser && (
              <>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition duration-300"
                    placeholder="Enter your full name"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Your Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition duration-300"
                    placeholder="Enter your email address"
                  />
                </div>
              </>
            )}

            <div>
              <label
                htmlFor="template"
                className="block text-gray-700 font-semibold mb-2"
              >
                Choose a message template (optional)
              </label>
              <select
                id="template"
                value={selectedTemplate}
                onChange={handleTemplateChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition duration-300"
              >
                <option value="">
                  Select a template or write your own message
                </option>
                {messageTemplates.map((template, index) => (
                  <option key={index} value={template}>
                    {template
                      .replace('{propertyTitle}', propertyTitle)
                      .substring(0, 50)}
                    ...
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-gray-700 font-semibold mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                rows={5}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition duration-300 resize-none"
                placeholder="Write your message here..."
              ></textarea>
            </div>

            <button
              type="submit"
              disabled={isSending}
              className="w-full bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-300 font-semibold disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
            >
              {isSending ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Sending Message...
                </div>
              ) : (
                'Send Inquiry'
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactLandlord;
